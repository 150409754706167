import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export const SpellingTest = () => {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  const applyCorrection = (originalText, error) => {
    const before = originalText.substring(0, error.offset);
    const after = originalText.substring(error.offset + error.length);
    return before + error.suggestion + after;
  };

  const checkSpelling = async (text) => {
    try {
      setLoading(true);
      const response = await axios.post('https://api.languagetool.org/v2/check', {
        text: text,
        language: 'es',
        enabledOnly: false
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.data.matches.length > 0) {
        let currentText = text;
        
        for (const match of response.data.matches) {
          const error = {
            message: match.message,
            suggestion: match.replacements[0]?.value || '',
            original: match.context.text.substring(match.context.offset, match.context.offset + match.context.length),
            offset: match.offset,
            length: match.length
          };

          const result = await Swal.fire({
            title: 'Error ortográfico encontrado',
            html: `
              <div style="text-align: left">
                <p>Se encontró el siguiente error:</p>
                <p>"${error.original}" → Sugerencia: "${error.suggestion}"</p>
                <p><small>${error.message}</small></p>
              </div>
            `,
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Corregir',
            denyButtonText: 'Omitir',
            cancelButtonText: 'Cancelar todo'
          });

          if (result.isDismissed) {
            // Si el usuario cancela, detenemos todo el proceso
            break;
          } else if (result.isConfirmed) {
            // Si el usuario acepta la corrección
            currentText = applyCorrection(currentText, error);
            setText(currentText);
          }
          // Si el usuario elige 'Omitir', simplemente continuamos con el siguiente error
        }

        await Swal.fire({
          title: 'Revisión completada',
          text: 'Se ha completado la revisión ortográfica.',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      } else {
        await Swal.fire({
          title: '¡Texto correcto!',
          text: 'No se encontraron errores ortográficos.',
          icon: 'success',
          confirmButtonText: 'Excelente'
        });
      }
    } catch (error) {
      console.error('Error checking spelling:', error);
      await Swal.fire({
        title: 'Error',
        text: 'Hubo un error al verificar la ortografía. Por favor, intenta de nuevo.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim()) {
      checkSpelling(text);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Prueba de Verificación Ortográfica</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-2">
            Ingresa el texto a verificar:
          </label>
          <textarea
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="w-full h-32 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Escribe aquí el texto que deseas verificar..."
          />
        </div>
        <button
          type="submit"
          disabled={loading || !text.trim()}
          className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
            ${loading || !text.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'}`}
        >
          {loading ? 'Verificando...' : 'Verificar ortografía'}
        </button>
      </form>
    </div>
  );
}; 