import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ModalCard from "../../../components/Modals/ModalCard";
import Modal2Xv from "../../../components/Modals/Modal2Xv";
import { LoadingComponentEn } from "../../../components/LoadingComponentEn";
import { PortadaBodaComponent2 } from "../../../components/ComponentsBoda2/PortadaBodaComponent2";
import { ContadorBodaComponent2 } from "../../../components/ComponentsBoda2/ContadorBodaComponent2";
import { InvitadosBodaComponent2 } from "../../../components/ComponentsBoda2/InvitadosBodaComponent2";
import { PadresBodaComponent2 } from "../../../components/ComponentsBoda2/PadresBodaComponent2";
import { PadrinosBodaComponent2 } from "../../../components/ComponentsBoda2/PadrinosBodaComponent2";
import { ProgramaBodaComponent2 } from "../../../components/ComponentsBoda2/ProgramaBodaComponent2";
import { UbicacionesBodaComponent2 } from "../../../components/ComponentsBoda2/UbicacionesBodaComponent2";
import { HospedajeBodaComponent2 } from "../../../components/ComponentsBoda2/HospedajeBodaComponent2";
import { VestimentaBodaComponent2 } from "../../../components/ComponentsBoda2/VestimentaBodaComponent2";
import { RegalosBodaComponent2 } from "../../../components/ComponentsBoda2/RegalosBodaComponent2";
import { InstagramBodaComponent2 } from "../../../components/ComponentsBoda2/InstagramBodaComponent2";
import { ConfirmacionBodaComponent2 } from "../../../components/ComponentsBoda2/ConfirmacionBodaComponent2";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import ErrorAlert from "../../../components/ErrorAlert";
import { ConvertDataContador } from "../../../components/functions/ConvertDataContador";

export const BodaAutomatica2 = () => {
  const [seccionPortadaData, setSeccionPortadaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seccionImagenes, setSeccionImagenes] = useState([]);
  const [estado, setEstado] = useState({});
  const [dataInvitado, setDataInvitado] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEnglish, setIsEnglish] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idClient = searchParams.get("id");

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirect, setRedirect] = useState(false);

  const handleError = (message) => {
    setErrorMessage(isEnglish ? 
      "Your event has been temporarily disabled. Please contact us for more information." :
      message
    );
    setShowError(true);
  };

  const handleRetry = () => {
    setShowError(false);
    fetchData();
  };

  // Función para hacer la solicitud HTTP
  const fetchData = async () => {
    setLoading(true);
    try {
      const responseEvento = await axios.get(`/api/Eventos/${idClient}`);

      console.log(responseEvento.data);
      if (responseEvento.data.enableEvento == true) {
        handleError(
          "Tu evento ha sido temporalmente deshabilitado. Por favor, contáctanos para más información."
        );
        setRedirect(true);
        return;
      }

      setEstado(responseEvento.data);
      setIsEnglish(responseEvento.data.ingles || false);
      
      try {
        const responsePortada = await axios.get(
          `/api/Seccionportada/obtenerDiseno2/${idClient}`
        );

        if (responsePortada.data.deuda) {
          handleError(isEnglish ? 
            "There is a pending payment. Please contact us." :
            responsePortada.data.smsdeuda
          );
          setRedirect(true);
          return;
        } else {
          if (responsePortada.data.smsdeuda && responsePortada.data.smsdeuda !== "") {
            handleError(isEnglish ? 
              "Important notice about your event" :
              responsePortada.data.smsdeuda
            );
          }
        }

        setSeccionPortadaData(responsePortada.data);

        try {
          const responseImg = await axios.get(`/api/Imagenes?id=${idClient}`);
          const mapImg = responseImg.data.map((item) => item.urlFoto);
          setSeccionImagenes(mapImg);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          handleError(isEnglish ?
            "We couldn't load the images. Please try again in a few minutes." :
            "No pudimos cargar las imágenes. Por favor, intenta de nuevo en unos minutos."
          );
        }
      } catch (error) {
        setLoading(false);
        handleError(isEnglish ?
          "There was a problem loading the event details. Please try again later." :
          "Hubo un problema al cargar los detalles del evento. Por favor, intenta de nuevo más tarde."
        );
      }
    } catch (error) {
      setLoading(false);
      handleError(isEnglish ?
        "We couldn't connect to the server. Please check your connection and try again." :
        "No pudimos conectar con el servidor. Por favor, verifica tu conexión e intenta de nuevo."
      );
    }
  };

  const peltaColores = {
    color1: seccionPortadaData?.colores?.color1 || "",
    color2: seccionPortadaData?.colores?.color2 || "",
  };

  const datosSeccionPotadaBoda = {
    cancion: seccionPortadaData?.portada?.cancion || "",
    textoConfirmacion: seccionPortadaData?.portada?.textoConfirmacion || "",
    esposo: seccionPortadaData?.portada?.esposo || "",
    esposa: seccionPortadaData?.portada?.esposa || "",
    fechaDeBoda: seccionPortadaData?.portada?.fechaDeBoda || "",
    imgPortada: seccionPortadaData?.portada?.imgPortada || "",
    titulo: seccionPortadaData?.portada?.titulo || "",
    textColor: seccionPortadaData?.portada?.textColor || "",
    tipoLetra: seccionPortadaData?.portada?.tipoLetra || "",
  };

  const datosSeccionContador = {
    fechaDeBoda: seccionPortadaData?.portada?.fechaDeBoda || "",
    titulo: seccionPortadaData?.contador?.titulo || "",
    descipcion: seccionPortadaData?.contador?.descripcion || "",
    fechaNewDate: seccionPortadaData?.contador?.fechaNewDate || "",
    fotoIzquierda: seccionPortadaData?.contador?.fotoIzquierda || "",
    fotoDerecha: seccionPortadaData?.contador?.fotoDerecha || "",
    marcoEnable: seccionPortadaData?.contador?.marcoEnable,
    imgFondo: seccionPortadaData?.contador?.imgFondo || "",
    textColor: seccionPortadaData?.contador?.textColor || "",
    contador: seccionPortadaData?.contador?.contador,
    tipoLetra: seccionPortadaData?.contador?.tipoLetra,
  };

  const datosSeccionPadres = {
    cantidad: seccionPortadaData?.padres?.cantidad || "",
    titulo: seccionPortadaData?.padres?.titulo || "",
    descripcion: seccionPortadaData?.padres?.descripcion || "",
    titulo1: seccionPortadaData?.padres?.titulo1 || "",
    madre: seccionPortadaData?.padres?.madre || "",
    padre: seccionPortadaData?.padres?.padre || "",
    titulo2: seccionPortadaData?.padres?.titulo2 || "",
    madrina: seccionPortadaData?.padres?.madrina || "",
    padrino: seccionPortadaData?.padres?.padrino || "",
    titulo3: seccionPortadaData?.padres?.titulo3 || "",
    madrina2: seccionPortadaData?.padres?.madrina2 || "",
    padrino2: seccionPortadaData?.padres?.padrino2 || "",
    imgFondo: seccionPortadaData?.padres?.imgFondo || "",
    textColor: seccionPortadaData?.padres?.textColor || "",
    tipoLetra: seccionPortadaData?.padres?.tipoLetra || "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: seccionPortadaData?.ceremonia?.dobleEnable,
    ceremoniaEnable: seccionPortadaData?.ceremonia?.ceremoniaEnable,
    ceremoniaImg: seccionPortadaData?.ceremonia?.ceremoniaImg,
    ceremoniaTitulo: seccionPortadaData?.ceremonia?.ceremoniaTitulo,
    ceremoniaFecha: seccionPortadaData?.ceremonia?.ceremoniaFecha || "",
    ceremoniaHora: seccionPortadaData?.ceremonia?.ceremoniaHora || "",
    ceremoniaNombreLugar:
      seccionPortadaData?.ceremonia?.ceremoniaNombreLugar || "",
    ceremoniaLugarMap: seccionPortadaData?.ceremonia?.ceremoniaLugarMap || "",
    ceremoniaUrl: seccionPortadaData?.ceremonia?.ceremoniaUrl || "",
    recepcionEnable: seccionPortadaData?.ceremonia?.recepcionEnable,
    recepcionImg: seccionPortadaData?.ceremonia?.recepcionImg,
    recepcionTitulo: seccionPortadaData?.ceremonia?.recepcionTitulo,
    recepcionFecha: seccionPortadaData?.ceremonia?.recepcionFecha || "",
    recepcionHora: seccionPortadaData?.ceremonia?.recepcionHora || "",
    recepcionNombreLugar:
      seccionPortadaData?.ceremonia?.recepcionNombreLugar || "",
    recepcionLugarMap: seccionPortadaData?.ceremonia?.recepcionLugarMap || "",
    recepcionUrl: seccionPortadaData?.ceremonia?.recepcionUrl || "",
    imgFondo: seccionPortadaData?.ceremonia?.imgFondo || "",
    textColor: seccionPortadaData?.ceremonia?.textColor || "",
    tipoLetra: seccionPortadaData?.ceremonia?.tipoLetra || "",
  };

  const datosSeccionProgramaBoda = {
    ceremoniaReligiosa: seccionPortadaData?.programa?.ceremoniaReligiosa || "",
    titulo: seccionPortadaData?.programa?.titulo || "",
    descripcion: seccionPortadaData?.programa?.descripcion || "",
    ceremoniaReligiosaImg:
      seccionPortadaData?.programa?.ceremoniaReligiosaImg || "",
    ceremoniaReligiosaTitulo:
      seccionPortadaData?.programa?.ceremoniaReligiosaTitulo || "",
    civil: seccionPortadaData?.programa?.civil || "",
    civilImg: seccionPortadaData?.programa?.civilImg || "",
    civilTitulo: seccionPortadaData?.programa?.civilTitulo || "",
    recepcion: seccionPortadaData?.programa?.recepcion || "",
    recepcionImg: seccionPortadaData?.programa?.recepcionImg || "",
    recepcionTitulo: seccionPortadaData?.programa?.recepcionTitulo || "",
    cena: seccionPortadaData?.programa?.cena || "",
    cenaImg: seccionPortadaData?.programa?.cenaImg || "",
    cenaTitulo: seccionPortadaData?.programa?.cenaTitulo || "",
    coctel: seccionPortadaData?.programa?.coctel || "",
    coctelImg: seccionPortadaData?.programa?.coctelImg || "",
    coctelTitulo: seccionPortadaData?.programa?.coctelTitulo || "",
    baile: seccionPortadaData?.programa?.baile || "",
    baileImg: seccionPortadaData?.programa?.baileImg || "",
    baileTitulo: seccionPortadaData?.programa?.baileTitulo || "",
    textColor: seccionPortadaData?.programa?.textColor || "",
    imgFondo: seccionPortadaData?.programa?.imgFondo || "",
    tipoLetra: seccionPortadaData?.programa?.tipoLetra || "",
  };

  const datosSeccionVestimenta = {
    vestimenta: seccionPortadaData?.vestimenta?.vestimenta || "",
    messageVestimenta: seccionPortadaData?.vestimenta?.messageVestimenta || "",
    urlVestimentaMujer:
      seccionPortadaData?.vestimenta?.urlVestimentaMujer || "",
    urlVestimentaHombre:
      seccionPortadaData?.vestimenta?.urlVestimentaHombre || "",
    imgFondo: seccionPortadaData?.vestimenta?.imgFondo || "",
    textColor: seccionPortadaData?.vestimenta?.textColor || "",
    tipoLetra: seccionPortadaData?.vestimenta?.tipoLetra || "",
    textoHombre: seccionPortadaData?.vestimenta?.textoHombre || "",
    textoMujer: seccionPortadaData?.vestimenta?.textoMujer || "",
    colores: seccionPortadaData?.vestimenta?.colores || "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: seccionPortadaData?.hospedaje?.disponibleDos || "",
    descripcion: seccionPortadaData?.hospedaje?.descripcion || "",
    hotel1Titulo: seccionPortadaData?.hospedaje?.hotel1Titulo || "",
    hotel1lugar: seccionPortadaData?.hospedaje?.hotel1lugar || "",
    hotel1url: seccionPortadaData?.hospedaje?.hotel1url || "",
    hotel2Titulo: seccionPortadaData?.hospedaje?.hotel2Titulo || "",
    hotel2lugar: seccionPortadaData?.hospedaje?.hotel2lugar || "",
    hotel2url: seccionPortadaData?.hospedaje?.hotel2url || "",
    imgFondo: seccionPortadaData?.hospedaje?.imgFondo || "",
    textColor: seccionPortadaData?.hospedaje?.textColor || "",
    tipoLetra: seccionPortadaData?.hospedaje?.tipoLetra || "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: seccionPortadaData?.regalos?.cantidadRegalos || "",
    titulo: seccionPortadaData?.regalos?.titulo || "",
    descripcion: seccionPortadaData?.regalos?.descripcion || "",
    link1: seccionPortadaData?.regalos?.link1 || "",
    imgLink1: seccionPortadaData?.regalos?.imgLink1 || "",
    link1Enable: seccionPortadaData?.regalos?.link1Enable || "",
    link2: seccionPortadaData?.regalos?.link2 || "",
    imgLink2: seccionPortadaData?.regalos?.imgLink2 || "",
    link2Enable: seccionPortadaData?.regalos?.link2Enable || "",
    link3: seccionPortadaData?.regalos?.link3 || "",
    imgLink3: seccionPortadaData?.regalos?.imgLink3 || "",
    link3Enable: seccionPortadaData?.regalos?.link3Enable || "",
    imgFondo: seccionPortadaData?.regalos?.imgFondo || "",
    textColor: seccionPortadaData?.regalos?.textColor || "",
    tipoLetra: seccionPortadaData?.regalos?.tipoLetra || "",
  };

  const datosSeccionConfirmacion = {
    enableNum: seccionPortadaData?.confirmacion?.enableNum,
    tituloConfirmacion:
      seccionPortadaData?.confirmacion?.tituloConfirmacion || "",
    aviso: seccionPortadaData?.confirmacion?.aviso || "",
    numeroNovia: seccionPortadaData?.confirmacion?.numeroNovia || "",
    numeroNovio: seccionPortadaData?.confirmacion?.numeroNovio || "",
    numeroConfirmacion:
      seccionPortadaData?.confirmacion?.numeroConfirmacion || "",
    textoConfirmacion:
      seccionPortadaData?.confirmacion?.textoConfirmacion || "",
    textWhatsApp: seccionPortadaData?.confirmacion?.textWhatsApp || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    imgFondo: seccionPortadaData?.confirmacion?.imgFondo || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    tipoLetra: seccionPortadaData?.confirmacion?.tipoLetra || "",
  };

  const datosSeccionInvitados = {
    aviso: seccionPortadaData?.confirmacion?.aviso || "",
    imgFondo: seccionPortadaData?.invitados?.imgFondo,
    titulo: isEnglish
    ? `You are invited to celebrate with us!`
    : `¡Has sido invitado ${
        seccionPortadaData?.invitados?.eventoTitulo || "a la boda"
      } de ${seccionPortadaData?.portada?.esposa} & ${
        seccionPortadaData?.portada?.esposo
      }!`,
    entradasText: seccionPortadaData?.invitados?.entradasText,
    colorFondoName: seccionPortadaData?.invitados?.colorFondoName,
    tipoLetra: seccionPortadaData?.invitados?.tipoLetra,
  };

  const contador = estado?.enableContador;
  const padres = estado?.enablePadres;
  const hospedaje = estado?.enableHospedaje;
  const confirmacion = estado?.enableConfirmacion;
  const Regalos = estado?.enableRegalos;
  const ceremonia = estado?.enableCeremonia;
  const programa = estado?.enablePrograma;
  const vestimenta = estado?.enableVestimenta;
  const invitado = estado?.enableInvitados;
  const carta = estado?.enableCarta;
  const instagram = estado?.enableInsta;

  const enableSecciones = {
    contador,
    padres,
    hospedaje,
    confirmacion,
    Regalos,
    programa,
    ceremonia,
    vestimenta,
    invitado,
    carta,
    instagram,
    isEnglish
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    fetchData();
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {showError && (
        <ErrorAlert 
          message={errorMessage}
          onRetry={handleRetry}
          redirect={redirect}
          onClose={() => setShowError(false)}
        />
      )}
      
      {loading ? (
        <LoadingComponentEn/>
      ) : (
        <>
          {!carta ? (
            seccionPortadaData?.portada?.sobre == null || seccionPortadaData?.portada?.sobre === 0 ? (
              <ModalCard
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                datosSeccionPortada={datosSeccionPotadaBoda}
                isEnglish={isEnglish}
              />
            ) : (
              <Modal2Xv
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                datosSeccionPortada={datosSeccionPotadaBoda}
                isEnglish={isEnglish}
              />
            )
          ) : (
            ""
          )}

          <PortadaBodaComponent2 
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            scrollToSection={scrollToSection}
            datosSeccionPotadaBoda={datosSeccionPotadaBoda}
            colores={peltaColores}
            enableSecciones={enableSecciones}
            isEnglish={isEnglish}
          />

          {contador ? (
            <div id="contador">
              <ContadorBodaComponent2 
                contadorData={datosSeccionContador}  
                peltaColores={peltaColores}
                isEnglish={isEnglish}
              />
            </div>
          ) : (
            ""
          )}

          {padres ? (
            <div id="padres">
              <PadresBodaComponent2 
                datosSeccionPadres={datosSeccionPadres}  
                colores={peltaColores} 
                fotoPadres={datosSeccionContador.fotoDerecha}
                isEnglish={isEnglish}
              />
            </div>
          ) : (
            ""
          )}

          {programa ? (
            <div id="programa">
              <ProgramaBodaComponent2 
                datosSeccionProgramaBoda={datosSeccionProgramaBoda}  
                colores={peltaColores}
                isEnglish={isEnglish}
              />
            </div>
          ) : (
            ""
          )}

          {ceremonia ? (
            <div id="ubicaciones">
              <UbicacionesBodaComponent2 
                datosSeccionCeremonia={datosSeccionCeremonia}  
                colores={peltaColores}
                isEnglish={isEnglish}
              />
            </div>
          ) : (
            ""
          )}

          {hospedaje ? (
            <div id="hospedaje">
              <HospedajeBodaComponent2 
                datosSeccionHospedaje={datosSeccionHospedaje}  
                colores={peltaColores}
                isEnglish={isEnglish}
              />
            </div>
          ) : (
            ""
          )}

          {vestimenta ? (
            <div id="vestimenta">
              <VestimentaBodaComponent2 
                datosSeccionVestimenta={datosSeccionVestimenta}  
                colores={peltaColores}
                isEnglish={isEnglish}
              />
            </div>
          ) : (
            ""
          )}

          {instagram ? (
            <div id="instagram">
             <InstagramBodaComponent2 
               datosSeccionInstagram={seccionPortadaData?.insta}
               isEnglish={isEnglish}
             />
            </div>
          ) : (
            ""
          )}
          
          {Regalos ? (
            <div id="regalos">
              <RegalosBodaComponent2 
                datosSeccionRegalos={datosSeccionRegalos}  
                colores={peltaColores}
                isEnglish={isEnglish}
              />
            </div>
          ) : (
            ""
          )}

          {confirmacion ? (
            <div id="confirmacion">
              <ConfirmacionBodaComponent2 
                datosSeccionConfirmacion={datosSeccionConfirmacion} 
                seccionImagenes={seccionImagenes}
                isEnglish={isEnglish}
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
