import React, { useEffect } from 'react';

export const LeadClickWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://cdn.jsdelivr.net/npm/@leadsales/leadclick@1.11.1/dist/leadclick/leadclick.esm.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <leadclick-widget
      integrations="2"
      fburl="https://www.facebook.com/profile.php?id=61556089589338"
      igurl="https://www.instagram.com/leadsales.io/?hl=es"
      waurl="https://api.whatsapp.com/send?phone=5214491516931&text=Hola%20buen%20d%C3%ADa%20me%20gustar%C3%ADa%20adquirir%20informaci%C3%B3n"
      cta="Contactanos"
      fontcolor="#ffffff"
      bgcolor="#000000"
      instructions="Por dónde nos quieres contactar?"
      orientation="right"
      visible_integrations="WhatsApp,Facebook"
      ispremium="false"
    >
    </leadclick-widget>
  );
}; 