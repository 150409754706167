'use client'

import { useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { motion } from 'framer-motion'
import { LeadClickWidget } from './LeadClickWidget'
import { FaWhatsapp, FaInstagram, FaFacebook } from 'react-icons/fa'

export const Home = ()=> {
  const [cartCount, setCartCount] = useState(0)
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0)
  const [currentDesignIndex, setCurrentDesignIndex] = useState(0)
  const [timeLeft, setTimeLeft] = useState(() => {
    // Obtener el tiempo restante guardado o iniciar un nuevo período de 24 horas
    const savedEndTime = localStorage.getItem('countdownEndTime');
    if (savedEndTime) {
      const endTime = parseInt(savedEndTime);
      const now = Date.now();
      if (now < endTime) {
        const diff = endTime - now;
        return {
          hours: Math.floor(diff / (1000 * 60 * 60)),
          minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((diff % (1000 * 60)) / 1000)
        };
      }
    }
    // Si no hay tiempo guardado o ya expiró, iniciar nuevo período
    const endTime = Date.now() + (24 * 60 * 60 * 1000);
    localStorage.setItem('countdownEndTime', endTime.toString());
    return {
      hours: 23,
      minutes: 59,
      seconds: 59
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev.seconds > 0) {
          return { ...prev, seconds: prev.seconds - 1 };
        } else if (prev.minutes > 0) {
          return { ...prev, minutes: prev.minutes - 1, seconds: 59 };
        } else if (prev.hours > 0) {
          return { hours: prev.hours - 1, minutes: 59, seconds: 59 };
        } else {
          // Reiniciar el contador cuando llegue a cero
          const newEndTime = Date.now() + (24 * 60 * 60 * 1000);
          localStorage.setItem('countdownEndTime', newEndTime.toString());
          return { hours: 23, minutes: 59, seconds: 59 };
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const categories = [
    { 
      title: 'Boda', 
      gradient: 'bg-gradient-to-br from-pink-200 via-rose-200 to-pink-300',
      icon: '💍',
      description: 'El día más especial merece una invitación extraordinaria'
    },
    { 
      title: 'XV Años', 
      gradient: 'bg-gradient-to-br from-purple-200 via-fuchsia-200 to-pink-200',
      icon: '👑',
      description: 'Una celebración única para un momento único'
    },
    { 
      title: 'Bautizo', 
      gradient: 'bg-gradient-to-br from-sky-200 via-cyan-200 to-blue-200',
      icon: '🕊️',
      description: 'Celebra este momento especial con una invitación única'
    },
    { 
      title: 'Baby shower', 
      gradient: 'bg-gradient-to-br from-yellow-100 via-amber-100 to-orange-100',
      icon: '🎀',
      description: 'Comparte la dulce espera con tus seres queridos'
    },
    { 
      title: 'Fiestas', 
      gradient: 'bg-gradient-to-br from-emerald-200 via-teal-200 to-cyan-200',
      icon: '🎉',
      description: 'Haz de tu celebración un momento inolvidable'
    }
  ]

  const featuredDesigns = [
    { image: 'https://i.postimg.cc/rFgH7w6L/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-1.png', title: 'Invitación XV Años Azul' },
    { image: 'https://i.postimg.cc/W1zsHyHX/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-2.png', title: 'Invitación Floral' },
    { image: 'https://i.postimg.cc/g0rr3jpc/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-4.png', title: 'Invitación Cenicienta' }
  ]

  const plans = [
    {
      name: "PREMIUM",
      popular: true,
      originalPrice: 3200,
      price: 995,
      savings: 2205,
      color: "bg-gradient-to-br from-purple-500 to-indigo-600",
      features: [
        "Todo lo incluido en el plan DIAMANTE",
        "Sección de Instagram con hashtag personalizado",
        "Feed de Instagram integrado",
        "Mensajes personalizados para cada invitado",
        "Control de acceso individual",
        "Panel de administración de invitados",
        "Estadísticas de visualización",
        "Notificaciones en tiempo real",
        "Soporte prioritario 24/7", 
        "QR de confirmación de invitación",
        "Control de asistencia por medio de QR"
      ],
      examples: [
        { text: "Ejemplo de XV años", href: "/ejemplos-xv" },
        { text: "Ejemplos de Boda", href: "/ejemplos-boda" }
      ]
    },
    {
      name: "DIAMANTE",
      originalPrice: 2500,
      price: 895,
      savings: 1605,
      color: "bg-blue-100",
      features: [
        "Todo lo incluido en el plan PLATA",
        "Personalización avanzada de invitaciones",
        "Confirmación directa a los anfitriones",
        "Sección de hospedaje para invitados",
        "Integración con mesa de regalos",
        "Código de vestimenta interactivo",
        "Sección especial para padres y padrinos",
        "Galería extendida con 8 fotografías"
      ],
      examples: [
        { text: "Ejemplo de XV años", href: "/ejemplos-xv" },
        { text: "Ejemplos de Boda", href: "/ejemplos-boda" }
      ]
    },
    {
      name: "ORO",
      originalPrice: 1200,
      price: 595,
      savings: 605,
      color: "bg-yellow-100",
      features: [
        "Todo lo incluido en el plan PLATA",
        "Sección de hospedaje para invitados",
        "Código de vestimenta interactivo",
        "Sección especial para padres y padrinos",
        "Galería con 6 fotografías",
        "Diseño premium personalizado"
      ],
      examples: [
        { text: "Ejemplo de XV años", href: "/ejemplos-xv" },
        { text: "Ejemplos de Boda", href: "/ejemplos-boda" }
      ]
    },
    {
      name: "PLATA",
      originalPrice: 600,
      price: 295,
      savings: 305,
      color: "bg-gray-100",
      features: [
        "App móvil para compartir tus invitaciones (iOS y Android)",
        "Plantilla de colores personalizable",
        "Confirmación vía WhatsApp",
        "Detalles completos del evento",
        "Cuenta regresiva interactiva",
        "Ubicación del evento y ceremonia en Maps",
        "Galería con 4 fotografías",
        "Música de fondo personalizable"
      ],
      examples: [
        { text: "Ejemplo de XV años", href: "/ejemplos-xv" },
        { text: "Ejemplos de Boda", href: "/ejemplos-boda" }
      ]
    }
  ]

  const nextCategory = () => {
    setCurrentCategoryIndex((prev) => (prev + 1) % categories.length)
  }

  const prevCategory = () => {
    setCurrentCategoryIndex((prev) => (prev - 1 + categories.length) % categories.length)
  }

  const nextDesign = () => {
    setCurrentDesignIndex((prev) => (prev + 1) % featuredDesigns.length)
  }

  const prevDesign = () => {
    setCurrentDesignIndex((prev) => (prev - 1 + featuredDesigns.length) % featuredDesigns.length)
  }

  // Configurar los handlers para el swipe
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => nextCategory(),
    onSwipedRight: () => prevCategory(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="min-h-screen bg-white relative">
      {/* Add this style tag for the snowfall animation */}

      {/* Add these snowflakes */}
      {[...Array(25)].map((_, index) => (
        <div
          key={index}
          className="snowflake"
          style={{
            left: `${Math.random() * 100}%`,
            top: `-${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 10}s`,
            fontSize: '20px',
          }}
        >
          <p className="text-sm">✨</p>
        </div>
      ))}

      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-white shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0">
              <h1 className="text-2xl font-serif italic text-[#8B7355]">Digital RSVP</h1>
            </div>
            <nav className="hidden md:flex space-x-8">
            </nav>
            <div className="flex items-center space-x-4">
              <div className="relative">
                {cartCount > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {cartCount}
                  </span>
                )}
              </div>
              <a href="https://wa.me/524491516931?text=Hola,%20me%20interesa%20obtener%20información%20sobre%20las%20invitaciones%20digitales" target="_blank" rel="noopener noreferrer" className="text-green-500 hover:text-green-600">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="pt-16 bg-green-800 text-white relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 py-12 md:py-20">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div className="space-y-4 md:space-y-6 text-center md:text-left">
              <h2 className="text-3xl md:text-5xl font-bold">Invitaciones Web</h2>
              <h3 className="text-2xl md:text-4xl font-serif text-[#D4AF37]">Personalizadas</h3>
              <p className="text-base md:text-lg">
                ¡Sorprende a tus invitados y lleva tu evento al siguiente nivel con diseños únicos y encantadores! 
                Crea una experiencia inolvidable para tu celebración especial. ✨
              </p>
              <a href="/ejemplos-xv" className="inline-block bg-[#D4AF37] text-white px-6 md:px-8 py-2 md:py-3 rounded-full font-semibold hover:bg-[#B8860B] transition-colors">
                Ver diseños
              </a>
            </div>
            <div className="relative">
              <div className="grid grid-cols-2 gap-2 md:gap-4 max-w-sm mx-auto md:max-w-none">
                <div className="transform rotate-[-5deg] transition-transform hover:scale-105">
                  <img 
                    src="https://i.postimg.cc/rFgH7w6L/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-1.png" 
                    alt="Invitación digital" 
                    className="rounded-xl md:rounded-3xl shadow-lg w-full"
                  />
                </div>
                <div className="transform rotate-[5deg] transition-transform hover:scale-105">
                  <img 
                    src="https://i.postimg.cc/g0rr3jpc/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-4.png" 
                    alt="Invitación digital" 
                    className="rounded-xl md:rounded-3xl shadow-lg w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Decorative Elements */}
        <div className="absolute top-10 right-10">
          <div className="text-4xl">✨</div>
        </div>
        <div className="absolute bottom-10 left-10">
          <div className="text-4xl">🌟</div>
        </div>
      </section>

      {/* Categories Section */}
      <section className="py-8 md:py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12 animate-fade-in">
            Tipos de Eventos
          </h2>
          <div className="relative">
            <div className="flex overflow-hidden touch-pan-x" {...swipeHandlers}>
              <div 
                className="flex transition-transform duration-500 ease-out w-full" 
                style={{ transform: `translateX(-${currentCategoryIndex * 100}%)` }}
              >
                {categories.map((category, index) => (
                  <div key={index} className="w-full flex-shrink-0 px-2 md:px-4">
                    <div className={`relative group cursor-pointer overflow-hidden rounded-xl md:rounded-2xl shadow-xl h-[150px] md:h-[200px] ${category.gradient} hover:shadow-2xl transform transition-all duration-300 hover:-translate-y-2`}>
                      <div className="absolute inset-0 flex flex-col items-center justify-center p-4 md:p-8 text-center">
                        <span className="text-4xl md:text-6xl mb-2 md:mb-4 animate-bounce">{category.icon}</span>
                        <h3 className="text-xl md:text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
                          {category.title}
                        </h3>
                        <p className="mt-1 md:mt-2 text-sm md:text-base text-gray-600 hidden md:block">{category.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Indicadores más grandes en móvil para mejor interacción */}
            <div className="flex justify-center mt-4 gap-3">
              {categories.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 md:w-2 md:h-2 rounded-full transition-all duration-300 ${
                    currentCategoryIndex === index ? 'bg-blue-500 w-5 md:w-4' : 'bg-gray-300'
                  }`}
                  onClick={() => setCurrentCategoryIndex(index)}
                />
              ))}
            </div>

            {/* Botones de navegación más grandes en móvil */}
            <button 
              onClick={prevCategory} 
              className="absolute left-2 md:left-4 top-1/2 transform -translate-y-1/2 bg-white/80 p-3 md:p-2 rounded-full shadow-lg hover:bg-white transition-all duration-200 hover:scale-110 z-10"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button 
              onClick={nextCategory} 
              className="absolute right-2 md:right-4 top-1/2 transform -translate-y-1/2 bg-white/80 p-3 md:p-2 rounded-full shadow-lg hover:bg-white transition-all duration-200 hover:scale-110 z-10"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-8 md:py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-8 md:mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Elige tu Plan Perfecto</h2>
            <p className="text-lg md:text-xl font-semibold">
              ✨ Oferta especial termina en: {String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:{String(timeLeft.seconds).padStart(2, '0')}
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8">
            {plans.map((plan, index) => (
              <div 
                key={index} 
                className={`bg-white mb-4 rounded-2xl shadow-xl overflow-hidden transform hover:scale-105 transition-transform duration-300 ${
                  plan.popular ? 'ring-4 ring-purple-500 scale-105 z-10' : ''
                }`}
              >
                <div className={`px-6 py-4 ${plan.color} ${plan.popular ? 'text-white' : ''}`}>
                  <h3 className="text-2xl font-bold text-center">{plan.name}</h3>
                  {plan.popular && (
                    <span className="block text-center text-sm bg-yellow-400 text-purple-900 px-3 py-1 rounded-full mx-auto w-max mt-2 font-semibold">
                      MÁS POPULAR
                    </span>
                  )}
                </div>
                
                <div className="p-6">
                  <div className="text-center mb-6">
                    <span className="text-gray-400 line-through text-lg">${plan.originalPrice}</span>
                    <div className="text-5xl font-bold text-gray-900 my-2">${plan.price}</div>
                    <div className="text-green-500 font-semibold">Ahorra ${plan.savings}</div>
                  </div>

                  <div className="mb-6">
                    <p className="text-sm text-gray-600 mb-4 font-medium">Elige uno de nuestros diseños exclusivos</p>
                    <div className="space-y-2">
                      {plan.examples.map((example, i) => (
                        <a 
                          key={i} 
                          href={example.href} 
                          className="block text-purple-600 hover:text-purple-700 hover:underline text-sm font-medium"
                        >
                          → {example.text}
                        </a>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-4 mb-8">
                    <h4 className="font-semibold text-gray-900">Incluye:</h4>
                    <ul className="space-y-3">
                      {plan.features.map((feature, i) => (
                        <li key={i} className="flex items-start gap-2 text-sm">
                          <svg className="w-5 h-5 text-green-500 shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          <span className="text-gray-600">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <button 
                    className={`w-full py-4 rounded-xl font-bold text-white transition-colors ${
                      plan.popular 
                        ? 'bg-purple-600 hover:bg-purple-700' 
                        : 'bg-blue-500 hover:bg-blue-600'
                    }`}
                  >
                    SELECCIONAR PLAN
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Beneficios Section */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Beneficios de las <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500">Invitaciones Digitales</span>
            </h2>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Descubre por qué las invitaciones digitales son la mejor opción para tu evento especial
            </p>
          </motion.div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: "🌍",
                title: "Ecológicas",
                description: "Contribuye al medio ambiente eliminando el uso de papel",
                color: "from-green-500 to-emerald-500"
              },
              {
                icon: "💰",
                title: "Ahorra Costos",
                description: "Evita gastos de impresión y envío tradicional",
                color: "from-blue-500 to-cyan-500"
              },
              {
                icon: "📱",
                title: "Fácil Distribución",
                description: "Comparte instantáneamente por WhatsApp, email o redes sociales",
                color: "from-purple-500 to-pink-500"
              }
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="group"
              >
                <div className="bg-white rounded-2xl shadow-xl p-8 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 relative overflow-hidden">
                  <div className={`absolute inset-0 bg-gradient-to-br ${benefit.color} opacity-0 group-hover:opacity-5 transition-opacity duration-300`}></div>
                  <div className="text-5xl mb-6 transform group-hover:scale-110 transition-transform duration-300">{benefit.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{benefit.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{benefit.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Características Destacadas */}
      <section className="py-20 bg-white relative">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Características <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">Destacadas</span>
            </h2>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Todo lo que necesitas para crear una invitación única y memorable
            </p>
          </motion.div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: "📍",
                title: "Ubicación con Google Maps",
                description: "Guía a tus invitados directamente al lugar del evento"
              },
              {
                icon: "📸",
                title: "Galería de Fotos",
                description: "Comparte tus mejores momentos con una galería personalizada"
              },
              {
                icon: "🎵",
                title: "Música de Fondo",
                description: "Añade tu canción favorita a la invitación"
              },
              {
                icon: "📅",
                title: "Confirmación de Asistencia",
                description: "Gestiona fácilmente la lista de invitados"
              },
              {
                icon: "⏰",
                title: "Cuenta Regresiva",
                description: "Mantén la emoción con un contador hasta el gran día"
              },
              {
                icon: "🎁",
                title: "Mesa de Regalos",
                description: "Integración con tiendas departamentales"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }}
                className="bg-gradient-to-br from-gray-50 to-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="bg-gradient-to-br from-purple-100 to-pink-100 w-16 h-16 rounded-xl flex items-center justify-center mb-6">
                  <span className="text-3xl">{feature.icon}</span>
                </div>
                <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-4xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Preguntas Frecuentes
            </h2>
            <p className="text-gray-600 text-lg">
              Todo lo que necesitas saber sobre nuestras invitaciones digitales
            </p>
          </motion.div>
          <div className="space-y-6">
            {[
              {
                question: "¿Cómo funciona la invitación digital?",
                answer: "La invitación es una página web personalizada que puedes compartir fácilmente a través de un enlace. Tus invitados podrán acceder desde cualquier dispositivo."
              },
              {
                question: "¿Puedo personalizar los colores y textos?",
                answer: "¡Sí! Puedes personalizar colores, textos, imágenes y otros elementos para que coincidan con tu estilo y temática."
              },
              {
                question: "¿Cómo comparto la invitación?",
                answer: "Puedes compartir el enlace de tu invitación por WhatsApp, correo electrónico, redes sociales o cualquier plataforma de mensajería."
              },
              {
                question: "¿Por cuánto tiempo estará disponible mi invitación?",
                answer: "Tu invitación estará disponible por 1 año desde la fecha de compra."
              }
            ].map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <h3 className="text-xl font-bold mb-4 text-gray-800">{faq.question}</h3>
                <p className="text-gray-600 leading-relaxed">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Featured Designs Section */}
      <section className="py-20 bg-white relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Nuestros <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Diseños</span> Destacados
            </h2>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Explora nuestra colección de diseños elegantes y modernos
            </p>
          </motion.div>
          <div className="relative">
            <div className="flex overflow-hidden rounded-3xl shadow-2xl">
              <div 
                className="flex transition-transform duration-500 ease-out"
                style={{ transform: `translateX(-${currentDesignIndex * 100}%)` }}
              >
                {featuredDesigns.map((design, index) => (
                  <div key={index} className="w-full flex-shrink-0 px-4">
                    <div className="relative group">
                      <img 
                        src={design.image} 
                        alt={design.title} 
                        className="w-[20vh] rounded-3xl h-[30vh] shadow-lg transform group-hover:scale-105 transition-transform duration-300" 
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-3xl flex items-end justify-center pb-8">
                        <h3 className="text-white text-xl font-bold">{design.title}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button 
              onClick={prevDesign} 
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/90 p-4 rounded-full shadow-lg hover:bg-white hover:scale-110 transition-all duration-300"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button 
              onClick={nextDesign}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/90 p-4 rounded-full shadow-lg hover:bg-white hover:scale-110 transition-all duration-300"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </section>

      {/* CTA Section con diseño mejorado */}
      <section className="py-20 bg-gradient-to-br from-purple-600 via-pink-500 to-purple-700 relative overflow-hidden">
        <div className="absolute inset-0 bg-pattern opacity-10"></div>
        <div className="max-w-4xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center text-white"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              ¿Listo para crear tu invitación?
            </h2>
            <p className="text-xl mb-12 text-white/90">
              Comienza ahora y sorprende a tus invitados con una invitación única
            </p>
            <div className="flex flex-col sm:flex-row gap-6 justify-center">
              <motion.a
                href="/ejemplos-boda"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-white text-purple-600 rounded-full font-bold shadow-lg hover:shadow-xl transition-all duration-300"
              >
                Ver Ejemplos de Boda
              </motion.a>
              <motion.a
                href="/ejemplos-xv"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-white text-pink-600 rounded-full font-bold shadow-lg hover:shadow-xl transition-all duration-300"
              >
                Ver Ejemplos de XV Años
              </motion.a>
            </div>
          </motion.div>
        </div>
      </section>


      {/* Agregar el widget de LeadClick */}
      <LeadClickWidget />
    </div>
  )
}

