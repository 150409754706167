import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import { PortadaBodaComponent } from "../../../components/ComponentsBoda/PortadaBodaComponent";
import { ContadorBodaComponent } from "../../../components/ComponentsBoda/ContadorBodaComponent";
import { PadresBodaComponent } from "../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../components/ComponentsXv/VestimentaXvComponent";
import { ProgramaBodaComponent } from "../../../components/ComponentsBoda/ProgramaBodaComponent";
import axios from "axios";
import { InvitadosXvComponent } from "../../../components/ComponentsXv/InvitadosXvComponent";
import { ConfirmacionBoda } from "../../../components/ConfirmacionBoda";
import ModalCard from "../../../components/Modals/ModalCard";
import ModalXvCard from "../../../components/Modals/ModalXvCard";
import { PortadaXvComponent } from "../../../components/ComponentsXv/PortadaXvComponent";
import { ProgramaXvComponent } from "../../../components/ComponentsXv/ProgramaXvComponent";
import { ConfirmacionXvComponent } from "../../../components/ComponentsXv/ConfirmacionXvComponent";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { ConvertDataContador } from "../../../components/functions/ConvertDataContador";
import Modal2Xv from "../../../components/Modals/Modal2Xv";
import ErrorAlert from "../../../components/ErrorAlert";

export const XvAutomaticaDiamante = () => {
  const [seccionPortadaData, setSeccionPortadaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seccionImagenes, setSeccionImagenes] = useState([]);
  const [estado, setEstado] = useState({});
  const [dataInvitado, setDataInvitado] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirect, setRedirect] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idInvitado = searchParams.get("idInvitado");

  const handleError = (message) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const handleRetry = () => {
    setShowError(false);
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true);

    const guestId = parseInt(idInvitado);

    try {
      const responseInvitado = await axios.get(
        `/api/Invitado/lista/${idInvitado}`
      );
      setDataInvitado(responseInvitado.data);
      let idEvento = responseInvitado.data.idEvento;

      try {
        const response = await axios.get(`/api/Eventos/${idEvento}`);
        if (response.data.enableEvento == true) {
          handleError(
            "¡Tu evento ha sido deshabilitado por falta de pago o algún otro motivo! Por favor, contáctanos."
          );
          setRedirect(true);
          return;
        }
        setEstado(response.data);
        console.log(response.data);

        try {
          const responsePortada = await axios.get(
            `/api/Seccionportada/obtenerXv/${idEvento}`
          );
          console.log(responsePortada.data);

          if (responsePortada.data.deuda) {
            handleError(responsePortada.data.smsdeuda);
            setRedirect(true);
            return;
          } else {
            if (responsePortada.data.smsdeuda && responsePortada.data.smsdeuda !== "") {
              handleError(responsePortada.data.smsdeuda);
            }
          }


          const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
          const validarDate = regex.test(responsePortada.data?.portada?.fechaDeBoda);

          if (validarDate) {
            const dataConvertida = await ConvertDataContador(responsePortada.data);
            setSeccionPortadaData(dataConvertida);
          } else {
            setSeccionPortadaData(responsePortada.data);
          }

          const massageWhatss = `Hola, soy ${responseInvitado.data.nombre} y confirmo mi asistencia ${responsePortada.data.confirmacion.textoConfirmacion} con ${responseInvitado.data.accesos} acceso(s). ¡Nos vemos allí!`;
          setMessageWhats(massageWhatss);

          try {
            const responseImg = await axios.get(`/api/Imagenes?id=${idEvento}`);
            const mapImg = responseImg.data.map((item) => item.urlFoto);
            setSeccionImagenes(mapImg);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            handleError(
              "No pudimos cargar las imágenes. Por favor, intenta de nuevo en unos minutos."
            );
          }
        } catch (error) {
          setLoading(false);
          handleError(
            "Hubo un problema al cargar los detalles del evento. Por favor, intenta de nuevo más tarde."
          );
        }
      } catch (error) {
        setLoading(false);
        handleError(
          "No pudimos conectar con el servidor. Por favor, verifica tu conexión e intenta de nuevo."
        );
      }
    } catch (error) {
      setLoading(false);
      handleError(
        "No pudimos encontrar tu invitación. Por favor, verifica el enlace o contacta a los organizadores."
      );
      window.location.href = "/";
    }
  };

  const peltaColores = {
    color1: seccionPortadaData?.colores?.color1 || "",
    color2: seccionPortadaData?.colores?.color2 || "",
  };

  const datosSeccionPotada = {
    cancion: seccionPortadaData?.portada?.cancion || "",
    textoConfirmacion: seccionPortadaData?.portada?.textoConfirmacion || "",
    nombre: seccionPortadaData?.portada?.nombre || "",
    fechaDeBoda: seccionPortadaData?.portada?.fechaDeBoda || "",
    imgPortada: seccionPortadaData?.portada?.imgPortada || "",
    titulo: seccionPortadaData?.portada?.titulo || "",
    tipoLetra: seccionPortadaData?.portada?.tipoLetra || "",
  };

  const datosSeccionContador = {
    titulo: seccionPortadaData?.contador?.titulo || "",
    descipcion: seccionPortadaData?.contador?.descripcion || "",
    fechaNewDate: seccionPortadaData?.contador?.fechaNewDate || "",
    fotoIzquierda: seccionPortadaData?.contador?.fotoIzquierda || "",
    fotoDerecha: seccionPortadaData?.contador?.fotoDerecha || "",
    marcoEnable: seccionPortadaData?.contador?.marcoEnable,
    imgFondo: seccionPortadaData?.contador?.imgFondo || "",
    textColor: seccionPortadaData?.contador?.textColor || "",
    contador: seccionPortadaData?.contador?.contador,
    tipoLetra: seccionPortadaData?.contador?.tipoLetra,
  };

  const datosSeccionPadres = {
    cantidad: seccionPortadaData?.padres?.cantidad || "",
    titulo: seccionPortadaData?.padres?.titulo || "",
    descripcion: seccionPortadaData?.padres?.descripcion || "",
    titulo1: seccionPortadaData?.padres?.titulo1 || "",
    madre: seccionPortadaData?.padres?.madre || "",
    padre: seccionPortadaData?.padres?.padre || "",
    titulo2: seccionPortadaData?.padres?.titulo2 || "",
    madrina: seccionPortadaData?.padres?.madrina || "",
    padrino: seccionPortadaData?.padres?.padrino || "",
    titulo3: seccionPortadaData?.padres?.titulo3 || "",
    madrina2: seccionPortadaData?.padres?.madrina2 || "",
    padrino2: seccionPortadaData?.padres?.padrino2 || "",
    imgFondo: seccionPortadaData?.padres?.imgFondo || "",
    textColor: seccionPortadaData?.padres?.textColor || "",
    tipoLetra: seccionPortadaData?.padres?.tipoLetra || "",
  };

  const datosSeccionCeremonia = {
    dobleEnable: seccionPortadaData?.ceremonia?.dobleEnable,
    ceremoniaEnable: seccionPortadaData?.ceremonia?.ceremoniaEnable,
    ceremoniaImg: seccionPortadaData?.ceremonia?.ceremoniaImg,
    ceremoniaTitulo: seccionPortadaData?.ceremonia?.ceremoniaTitulo,
    ceremoniaFecha: seccionPortadaData?.ceremonia?.ceremoniaFecha || "",
    ceremoniaHora: seccionPortadaData?.ceremonia?.ceremoniaHora || "",
    ceremoniaNombreLugar:
      seccionPortadaData?.ceremonia?.ceremoniaNombreLugar || "",
    ceremoniaLugarMap: seccionPortadaData?.ceremonia?.ceremoniaLugarMap || "",
    ceremoniaUrl: seccionPortadaData?.ceremonia?.ceremoniaUrl || "",
    recepcionEnable: seccionPortadaData?.ceremonia?.recepcionEnable,
    recepcionImg: seccionPortadaData?.ceremonia?.recepcionImg,
    recepcionTitulo: seccionPortadaData?.ceremonia?.recepcionTitulo,
    recepcionFecha: seccionPortadaData?.ceremonia?.recepcionFecha || "",
    recepcionHora: seccionPortadaData?.ceremonia?.recepcionHora || "",
    recepcionNombreLugar:
      seccionPortadaData?.ceremonia?.recepcionNombreLugar || "",
    recepcionLugarMap: seccionPortadaData?.ceremonia?.recepcionLugarMap || "",
    recepcionUrl: seccionPortadaData?.ceremonia?.recepcionUrl || "",
    imgFondo: seccionPortadaData?.ceremonia?.imgFondo || "",
    textColor: seccionPortadaData?.ceremonia?.textColor || "",
    tipoLetra: seccionPortadaData?.ceremonia?.tipoLetra || "",
  };

  const datosSeccionPrograma = {
    ceremoniaReligiosa: seccionPortadaData?.programa?.ceremoniaReligiosa || "",
    ceremoniaReligiosaImg:
      seccionPortadaData?.programa?.ceremoniaReligiosaImg || "",
    titulo: seccionPortadaData?.programa?.titulo || "",
    descripcion: seccionPortadaData?.programa?.descripcion || "",
    ceremoniaReligiosaTitulo:
      seccionPortadaData?.programa?.ceremoniaReligiosaTitulo || "",
    recepcion: seccionPortadaData?.programa?.recepcion || "",
    recepcionImg: seccionPortadaData?.programa?.recepcionImg || "",
    recepcionTitulo: seccionPortadaData?.programa?.recepcionTitulo || "",
    baileXv: seccionPortadaData?.programa?.baileXv || "",
    baileXvImg: seccionPortadaData?.programa?.baileXvImg || "",
    baileXvTitulo: seccionPortadaData?.programa?.baileXvTitulo || "",
    cena: seccionPortadaData?.programa?.cena || "",
    cenaImg: seccionPortadaData?.programa?.cenaImg || "",
    cenaTitulo: seccionPortadaData?.programa?.cenaTitulo || "",
    coctel: seccionPortadaData?.programa?.coctel || "",
    coctelImg: seccionPortadaData?.programa?.coctelImg || "",
    coctelTitulo: seccionPortadaData?.programa?.coctelTitulo || "",
    baile: seccionPortadaData?.programa?.baile || "",
    baileImg: seccionPortadaData?.programa?.baileImg || "",
    baileTitulo: seccionPortadaData?.programa?.baileTitulo || "",
    imgFondo: seccionPortadaData?.programa?.imgFondo || "",
    textColor: seccionPortadaData?.programa?.textColor || "",
    tipoLetra: seccionPortadaData?.programa?.tipoLetra || "",
  };

  const datosSeccionVestimenta = {
    vestimenta: seccionPortadaData?.vestimenta?.vestimenta || "",
    messageVestimenta: seccionPortadaData?.vestimenta?.messageVestimenta || "",
    urlVestimentaMujer:
      seccionPortadaData?.vestimenta?.urlVestimentaMujer || "",
    urlVestimentaHombre:
      seccionPortadaData?.vestimenta?.urlVestimentaHombre || "",
    imgFondo: seccionPortadaData?.vestimenta?.imgFondo || "",
    textColor: seccionPortadaData?.vestimenta?.textColor || "",
    tipoLetra: seccionPortadaData?.vestimenta?.tipoLetra || "",
  };

  const datosSeccionHospedaje = {
    disponibleDos: seccionPortadaData?.hospedaje?.disponibleDos || "",
    descripcion: seccionPortadaData?.hospedaje?.descripcion || "",
    hotel1Titulo: seccionPortadaData?.hospedaje?.hotel1Titulo || "",
    hotel1lugar: seccionPortadaData?.hospedaje?.hotel1lugar || "",
    hotel1url: seccionPortadaData?.hospedaje?.hotel1url || "",
    hotel2Titulo: seccionPortadaData?.hospedaje?.hotel2Titulo || "",
    hotel2lugar: seccionPortadaData?.hospedaje?.hotel2lugar || "",
    hotel2url: seccionPortadaData?.hospedaje?.hotel2url || "",
    imgFondo: seccionPortadaData?.hospedaje?.imgFondo || "",
    textColor: seccionPortadaData?.hospedaje?.textColor || "",
    tipoLetra: seccionPortadaData?.hospedaje?.tipoLetra || "",
  };

  const datosSeccionRegalos = {
    cantidadRegalos: seccionPortadaData?.regalos?.cantidadRegalos || "",
    titulo: seccionPortadaData?.regalos?.titulo || "",
    descripcion: seccionPortadaData?.regalos?.descripcion || "",
    link1: seccionPortadaData?.regalos?.link1 || "",
    imgLink1: seccionPortadaData?.regalos?.imgLink1 || "",
    link1Enable: seccionPortadaData?.regalos?.link1Enable || "",
    link2: seccionPortadaData?.regalos?.link2 || "",
    imgLink2: seccionPortadaData?.regalos?.imgLink2 || "",
    link2Enable: seccionPortadaData?.regalos?.link2Enable || "",
    link3: seccionPortadaData?.regalos?.link3 || "",
    imgLink3: seccionPortadaData?.regalos?.imgLink3 || "",
    link3Enable: seccionPortadaData?.regalos?.link3Enable || "",
    imgFondo: seccionPortadaData?.regalos?.imgFondo || "",
    textColor: seccionPortadaData?.regalos?.textColor || "",
    tipoLetra: seccionPortadaData?.regalos?.tipoLetra || "",
  };

  const datosSeccionConfirmacion = {
    tituloConfirmacion:
      seccionPortadaData?.confirmacion?.tituloConfirmacion || "",
    aviso: seccionPortadaData?.confirmacion?.aviso || "",
    numeroConfirmacion:
      seccionPortadaData?.confirmacion?.numeroConfirmacion || "",
    textoConfirmacion:
      seccionPortadaData?.confirmacion?.textoConfirmacion || "",
    textWhatsApp: seccionPortadaData?.confirmacion?.textWhatsApp || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    imgFondo: seccionPortadaData?.confirmacion?.imgFondo || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    enableNum: seccionPortadaData?.confirmacion?.enableNum || "",
    tipoLetra: seccionPortadaData?.confirmacion?.tipoLetra || "",
    confetti: seccionPortadaData?.confirmacion?.confetti || "",
  };

  const datosSeccionInvitados = {
    imgFondo: seccionPortadaData?.invitados?.imgFondo,
    titulo: `¡Has sido invitado ${seccionPortadaData?.invitados?.eventoTitulo || "a los XV años"
      } de ${seccionPortadaData?.portada?.nombre}!`,
    entradasText: seccionPortadaData?.invitados?.entradasText,
    colorFondoName: seccionPortadaData?.invitados?.colorFondoName,
    tipoLetra: seccionPortadaData?.invitados?.tipoLetra,
  };

  const contador = estado?.enableContador;
  const padres = estado?.enablePadres;
  const hospedaje = estado?.enableHospedaje;
  const confirmacion = estado?.enableConfirmacion;
  const Regalos = estado?.enableRegalos;
  const ceremonia = estado?.enableCeremonia;
  const programa = estado?.enablePrograma;
  const vestimenta = estado?.enableVestimenta;
  const invitado = estado?.enableInvitados;
  const carta = estado?.enableCarta;

  const [messageWhats, setMessageWhats] = useState("");

  const names = [
    { name: "Angel Jaimes", guess: "2", id: 1 },
    { name: "Samuel Arroyo", guess: "3", id: 2 },
  ];

  useEffect(() => {
    AOS.init({ duration: 2000 });
    fetchData();
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      {showError && (
        <ErrorAlert
          message={errorMessage}
          onRetry={handleRetry}
          redirect={redirect}
          onClose={() => setShowError(false)}
        />
      )}

      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {!carta ? (
            seccionPortadaData?.portada?.sobre == null || seccionPortadaData?.portada?.sobre === 0 ? (
              <ModalXvCard
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                datosSeccionPortada={datosSeccionPotada}
              />
            ) : (
              <Modal2Xv
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                datosSeccionPortada={datosSeccionPotada}
              />
            )
          ) : (
            ""
          )}
          <PortadaXvComponent datosSeccionPortada={datosSeccionPotada} />

          {contador ? (
            <ContadorBodaComponent
              peltaColores={peltaColores}
              datosSeccionContador={datosSeccionContador}
            />
          ) : (
            ""
          )}

          {invitado ? (
            <InvitadosXvComponent
              NameInvitation={dataInvitado.nombre}
              numGuess={dataInvitado.accesos}
              peltaColores={peltaColores}
              datosSeccionInvitados={datosSeccionInvitados}
            />
          ) : (
            ""
          )}

          {padres ? (
            <PadresBodaComponent
              peltaColores={peltaColores}
              datosSeccionPadres={datosSeccionPadres}
            />
          ) : (
            ""
          )}

          {ceremonia ? (
            <CeremoniaXvComponent
              peltaColores={peltaColores}
              datosSeccionCeremonia={datosSeccionCeremonia}
            />
          ) : (
            ""
          )}

          {programa ? (
            <ProgramaXvComponent
              peltaColores={peltaColores}
              datosSeccionPrograma={datosSeccionPrograma}
            />
          ) : (
            ""
          )}

          {vestimenta ? (
            <VestimentaXvComponent
              peltaColores={peltaColores}
              datosSeccionVestimenta={datosSeccionVestimenta}
            />
          ) : (
            ""
          )}

          {hospedaje ? (
            <HospedajeXvComponent
              peltaColores={peltaColores}
              datosSeccionHospedaje={datosSeccionHospedaje}
            />
          ) : (
            ""
          )}

          {Regalos ? (
            <RegalosXvComponent
              peltaColores={peltaColores}
              datosSeccionRegalos={datosSeccionRegalos}
            />
          ) : (
            ""
          )}

          {confirmacion ? (
            <ConfirmacionDiamXvComponent
              peltaColores={peltaColores}
              images={seccionImagenes}
              messageWhats={messageWhats}
              datosSeccionConfirmacion={datosSeccionConfirmacion}
            />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
