import { FaHome, FaCalendarAlt, FaHeart, FaEnvelope, FaTimes, FaMusic, FaPause, FaSpinner, FaInstagram, FaHandPaper } from 'react-icons/fa';
import { GrGallery } from 'react-icons/gr';
import { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import { FaPeopleRobbery } from 'react-icons/fa6';
import { MdAccessTime } from 'react-icons/md';
import { ImManWoman } from 'react-icons/im';
import FormattedText from '../functions/FormattedText';

export const PortadaBodaComponent2 = ({ isMenuOpen, setIsMenuOpen, scrollToSection, datosSeccionPotadaBoda, colores, enableSecciones, isEnglish }) => {
    const [player, setPlayer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    console.log(colores);

    const fontFamilyStyle = {
        fontFamily: datosSeccionPotadaBoda.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
    };

    // Extract YouTube video ID from URL
    const getYouTubeId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const videoId = getYouTubeId(datosSeccionPotadaBoda.cancion);

    const opts = {
        height: '0',
        width: '0',
        playerVars: {
            autoplay: 0,
            controls: 0,
        },
    };

    const onReady = (event) => {
        setPlayer(event.target);
        setIsLoading(false);
        // Auto-play when ready
        event.target.playVideo();
        setIsPlaying(true);
    };

    const togglePlay = () => {
        if (isPlaying) {
            player?.pauseVideo();
        } else {
            player?.playVideo();
        }
        setIsPlaying(!isPlaying);
    };

    const menuItems = [
        { id: 'inicio', icon: <FaHome className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: isEnglish ? 'Home' : 'Inicio', always: true },
        { id: 'contador', icon: <FaCalendarAlt className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: isEnglish ? 'Counter' : 'Contador', enabled: enableSecciones.contador },
        { id: 'invitados', icon: <FaHandPaper  className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: isEnglish ? 'Guests' : 'Invitados', enabled: enableSecciones.invitado },
        { id: 'padres', icon: <FaPeopleRobbery  className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: isEnglish ? 'Parents' : 'Padres', enabled: enableSecciones.padres },
        { id: 'programa', icon: <MdAccessTime  className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: isEnglish ? 'Schedule' : 'Itinerario', enabled: enableSecciones.programa },
        { id: 'ubicaciones', icon: <FaHeart className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: isEnglish ? 'Ceremony' : 'Ceremonia', enabled: enableSecciones.ceremonia },
        { id: 'hospedaje', icon: <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 group-hover:scale-110 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" /></svg>, label: isEnglish ? 'Lodging' : 'Hospedaje', enabled: enableSecciones.hospedaje },
        { id: 'vestimenta', icon: <ImManWoman className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: isEnglish ? 'Dress Code' : 'Vestimenta', enabled: enableSecciones.vestimenta },
        { id: 'instagram', icon: <FaInstagram className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: 'Instagram', enabled: enableSecciones.instagram },
        { id: 'regalos', icon: <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 group-hover:scale-110 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" /></svg>, label: isEnglish ? 'Gift Registry' : 'Mesa de Regalos', enabled: enableSecciones.Regalos },
        { id: 'confirmacion', icon: <GrGallery  className="w-6 h-6 group-hover:scale-110 transition-transform" />, label: isEnglish ? 'Gallery' : 'Galeria', enabled: enableSecciones.confirmacion },
    ];

    return (
        <div className="min-h-screen" style={{backgroundColor: colores?.color1 || '#f5f0e9'}}>
            {/* YouTube Player (hidden) */}
            {videoId && (
                <div style={{ display: 'none' }}>
                    <YouTube
                        videoId={videoId}
                        opts={opts}
                        onReady={onReady}
                    />
                </div>
            )}

            {/* Music control button */}
            <button
                onClick={togglePlay}
                className="fixed bottom-6 right-6 z-50 p-3 rounded-full bg-opacity-80 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-100 shadow-lg"
                style={{backgroundColor: colores?.color1 || '#f5f0e9'}}
                disabled={isLoading}
            >
                {isLoading ? (
                    <FaSpinner className="w-4 h-4 text-[#9a8e80] animate-spin" />
                ) : isPlaying ? (
                    <FaPause className="w-4 h-4 text-[#9a8e80]" />
                ) : (
                    <FaMusic className="w-4 h-4 text-[#9a8e80]" />
                )}
            </button>

            {/* Menu button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="fixed top-6 right-6 z-50 p-2 rounded-full bg-opacity-80 backdrop-blur-sm transition-all duration-300 hover:bg-opacity-100"
              style={{backgroundColor: colores?.color1 || '#f5f0e9'}}
            >
              <div className="w-6 h-5 relative flex flex-col justify-between">
                <span className={`w-full h-0.5 bg-[#9a8e80] transform transition-all duration-300 ${isMenuOpen ? "rotate-45 translate-y-2" : ""}`} />
                <span className={`w-full h-0.5 bg-[#9a8e80] transition-all duration-300 ${isMenuOpen ? "opacity-0" : ""}`} />
                <span className={`w-full h-0.5 bg-[#9a8e80] transform transition-all duration-300 ${isMenuOpen ? "-rotate-45 -translate-y-2" : ""}`} />
              </div>
            </button>

            {/* Side menu */}
            <div
              className={`fixed inset-0 z-40 transform transition-transform duration-500 ease-in-out shadow-lg ${isMenuOpen ? "translate-x-0" : "translate-x-full"}`}
              style={{backgroundColor: colores?.color1 || '#f5f0e9'}}
            >
              <nav className="h-full flex flex-col items-center justify-center space-y-8 px-8">
                <h2 style={fontFamilyStyle} className="text-3xl text-[#9a8e80] mb-4 border-b-2 border-[#9a8e80] pb-2">
                  {isEnglish ? 'Menu' : 'Menú'}
                </h2>

                <ul className="space-y-2 text-center w-full max-w-xs">
                  {menuItems.map((item) => (
                    (item.always || item.enabled) && (
                      <li key={item.id}>
                        <button
                          onClick={() => scrollToSection(item.id)}
                          className="flex items-center gap-3 text-[#9a8e80] hover:text-[#746b60] transition-all duration-300 group p-2 rounded-lg hover:bg-[#9a8e80]/10 w-full"
                        >
                          {item.icon}
                          <span style={fontFamilyStyle} className="text-lg">{item.label}</span>
                        </button>
                      </li>
                    )
                  ))}
                </ul>

                
              </nav>
            </div>

            {/* Main content */}
            <main className="relative min-h-screen">
              {datosSeccionPotadaBoda.titulo !== "Nada" && (
                <header id="inicio" className="absolute top-0 left-0 right-0 z-30 pt-16 pb-8 text-center">
                  <div className="max-w-md mx-auto px-4">
                    <h1 style={fontFamilyStyle} className={`text-4xl text-[${datosSeccionPotadaBoda.textColor || '#ffffff'}] mb-2`}>{datosSeccionPotadaBoda.esposa[0]}|{datosSeccionPotadaBoda.esposo[0]}</h1>
                    <h2 style={fontFamilyStyle} className={`text-2xl text-[${datosSeccionPotadaBoda.textColor || '#ffffff'}] flex justify-center items-center`}>
                      <span className={`w-[40%] text-right ${Math.max(datosSeccionPotadaBoda.esposa.length, datosSeccionPotadaBoda.esposo.length) > 8 ? 'text-xl' : 'text-3xl'}`}>
                        <FormattedText text={datosSeccionPotadaBoda.esposa} />
                      </span>
                      <span className="w-[20%] text-center text-2xl">&</span>
                      <span className={`w-[40%] text-left ${Math.max(datosSeccionPotadaBoda.esposa.length, datosSeccionPotadaBoda.esposo.length) > 8 ? 'text-xl' : 'text-3xl'}`}>
                        <FormattedText text={datosSeccionPotadaBoda.esposo} />
                      </span>
                    </h2>
                  </div>
                </header>
              )}

              <div className="relative h-screen">
                <div className="absolute inset-0">
                  <div className="absolute inset-0 bg-black opacity-5" />
                  <img
                    src={datosSeccionPotadaBoda.imgPortada}
                    alt={`${datosSeccionPotadaBoda.esposa} & ${datosSeccionPotadaBoda.esposo}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </main>
          </div>
    )
}
