import React, { useState, useEffect, useMemo } from 'react';
import { FaRegTrashAlt, FaWhatsapp, FaEye, FaPlus, FaPaperPlane, FaEnvelope, FaCheck, FaTimes, FaQrcode, FaBars, FaQuestionCircle, FaSearch, FaEdit, FaCheckCircle, FaTimesCircle, FaClock, FaUsers } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { Html5QrcodeScanner } from 'html5-qrcode';

let isEnglish = false;

const InvitadosTabla2 = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idEvento = searchParams.get("id");

  const [invitados, setInvitados] = useState([]);
  const [filteredInvitados, setFilteredInvitados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nuevoInvitado, setNuevoInvitado] = useState({
    Nombre: "",
    Accesos: "",
  });
  const [dataEvento, setDataEvento] = useState({});
  const [totalInvitados, setTotalInvitados] = useState(0);
  const [totalConfirmados, setTotalConfirmados] = useState(0);
  const [totalPendientes, setTotalPendientes] = useState(0);
  const [totalNoAsistiran, setTotalNoAsistiran] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [activeFilter, setActiveFilter] = useState('all');
  const [showSidebar, setShowSidebar] = useState(window.innerWidth >= 1024);

  // New states for QR scanner
  const [showScanner, setShowScanner] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const [qrScanner, setQrScanner] = useState(null);

  const [showInstructionsModal, setShowInstructionsModal] = useState(true);

  // Add searchTerm state
  const [searchTerm, setSearchTerm] = useState('');

  // Add edit states
  const [editingGuest, setEditingGuest] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editForm, setEditForm] = useState({ nombre: '', numeroAccesos: 0 });

  // Add new states for scan feedback
  const [isConfirming, setIsConfirming] = useState(false);

  // Add new state for QR confirmed count
  const [totalConfirmadosQR, setTotalConfirmadosQR] = useState(0);

  // Add show messages modal
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [viewAllMessages, setViewAllMessages] = useState(false);

  // Add success message component
  const SuccessMessage = ({ invitado }) => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl">
        <div className="text-center">
          <div className="w-16 h-16 bg-green-100 rounded-full mx-auto flex items-center justify-center">
            <FaCheck className="text-green-600 text-3xl" />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-900">
            {isEnglish ? "Guest Found!" : "¡Invitado Encontrado!"}
          </h3>
          
          <div className="mt-6 bg-green-50 rounded-lg p-4">
            <div className="text-left space-y-3">
              <div>
                <span className="font-medium text-green-800">
                  {isEnglish ? "Name: " : "Nombre: "}
                </span>
                <span className="text-green-900">{invitado.nombre}</span>
              </div>
              <div>
                <span className="font-medium text-green-800">
                  {isEnglish ? "Passes: " : "Pases: "}
                </span>
                <span className="text-green-900">{invitado.accesos}</span>
              </div>
              <div>
                <span className="font-medium text-green-800">
                  {isEnglish ? "Status: " : "Estado: "}
                </span>
                <span className="text-green-900">{getStatusText(invitado.link)}</span>
              </div>
            </div>
          </div>

          <div className="mt-6 space-y-3">
            <button
              onClick={() => {
                setScanResult(null);
              }}
              className="w-full inline-flex justify-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none"
            >
              {isEnglish ? "Continue Scanning" : "Continuar Escaneando"}
            </button>
            <button
              onClick={() => {
                setScanResult(null);
                setShowScanner(false);
                window.location.reload();
              }}
              className="w-full inline-flex justify-center px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 focus:outline-none"
            >
              {isEnglish ? "Exit Scanner" : "Salir del Escáner"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Add loading component
  const LoadingMessage = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-600 mx-auto"></div>
          <h3 className="mt-4 text-xl font-semibold text-gray-900">
            {isEnglish ? "Confirming Guest..." : "Confirmando Invitado..."}
          </h3>
        </div>
      </div>
    </div>
  );

  // Add search filter function
  const filteredGuests = useMemo(() => {
    const guestsToFilter = activeFilter === 'all' ? invitados : filteredInvitados;
    if (!searchTerm.trim()) return guestsToFilter;
    
    return guestsToFilter.filter(invitado => 
      invitado.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [invitados, filteredInvitados, activeFilter, searchTerm]);

  const fetchInvitados = async () => {
    try {
      setLoading(true);
      const responseEvento = await axios.get(`/api/Eventos/${idEvento}`);
      setDataEvento(responseEvento.data);
      isEnglish = responseEvento.data.ingles || false;
      
      const response = await axios.get(`/api/Invitado/evento/${idEvento}`);
      const invitadosList = response.data;
      setInvitados(invitadosList);
      console.log(invitadosList);

      // Calculate totals
      const confirmados = invitadosList.filter(inv => inv.link === 'si' || inv.link === 'confirmado-qr').length;
      const noAsistiran = invitadosList.filter(inv => inv.link === 'no').length;
      const pendientes = invitadosList.filter(inv => !inv.link || inv.link === '').length;
      
      setTotalInvitados(invitadosList.length);
      setTotalConfirmados(confirmados);
      setTotalConfirmadosQR(invitadosList.filter(inv => inv.link === 'confirmado-qr').length);
      setTotalNoAsistiran(noAsistiran);
      setTotalPendientes(pendientes);
      
      handleFilter('todos', invitadosList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(isEnglish ? "Error fetching guests:" : "Error al obtener invitados:", error);
    }
  };

  const handleFilter = (filter, guestList = invitados) => {
    switch (filter) {
      case 'confirmed':
        setFilteredInvitados(guestList.filter(inv => inv.link === 'si'));
        break;
      case 'confirmedQR':
        setFilteredInvitados(guestList.filter(inv => inv.link === 'confirmado-qr'));
        break;
      case 'pending':
        setFilteredInvitados(guestList.filter(inv => inv.link === 'pendiente'));
        break;
      case 'notAttending':
        setFilteredInvitados(guestList.filter(inv => inv.link === 'no'));
        break;
      default:
        setFilteredInvitados(guestList);
    }
    setActiveFilter(filter);
    setShowSidebar(false);
  };

  const deleteInvitado = async (idInvitado) => {
    try {
      const response = await axios.delete(`/api/Invitado/${idInvitado}`);
      if (response.status === 200) {
        // Reload the page after successful deletion
        window.location.reload();
      }
    } catch (error) {
      console.error('Error deleting guest:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoInvitado(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAgregarInvitado = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    try {
      const invitado = {
        nombre: nuevoInvitado.Nombre,
        accesos: nuevoInvitado.Accesos,
        idEvento: parseInt(idEvento),
        link: "pendiente",
        mensajeInvitado: "",
      };
      const response = await axios.post("/api/Invitado", invitado);
      setInvitados(invitados.push(response.data));
      setTotalInvitados(prev => prev + 1);
      setTotalPendientes(prev => prev + 1);
      setNuevoInvitado({ Nombre: "", Accesos: "" });
      setShowForm(false);
      handleFilter(activeFilter);
    } catch (error) {
      console.error(isEnglish ? "Error adding guest:" : "Error al agregar invitado:", error);
      alert(error.response?.data || (isEnglish ? "Error adding guest" : "Error al agregar invitado"));
    } finally {
      setIsAdding(false);
    }
  };

  useEffect(() => {
    fetchInvitados();
    return () => {
      if (qrScanner) {
        qrScanner.clear();
      }
    };
  }, []);

  const initializeScanner = () => {
    try {
      setShowScanner(true);
      
      // Small delay to ensure element exists
      setTimeout(() => {
        // Check if element exists
        const qrElement = document.getElementById('qr-reader');
        if (!qrElement) {
          console.error('QR reader element not found');
          setShowScanner(false);
          return;
        }

        // Clear previous scanner if exists
        if (qrScanner) {
          qrScanner.clear();
          setQrScanner(null);
        }

        // Enhanced scanner configuration
        const config = {
          fps: 10,
          qrbox: {
            width: 250,
            height: 250
          },
          rememberLastUsedCamera: true,
          showTorchButtonIfSupported: true,
          aspectRatio: 1.0
        };

        const html5QrcodeScanner = new Html5QrcodeScanner(
          "qr-reader",
          config,
          /* verbose= */ false
        );

        html5QrcodeScanner.render(
          (decodedText) => {
            handleQrCodeSuccess(decodedText, html5QrcodeScanner);
          },
          (errorMessage) => {
            handleQrCodeError(errorMessage);
          }
        ).catch(error => {
          console.error('Error rendering scanner:', error);
          setShowScanner(false);
        });

        setQrScanner(html5QrcodeScanner);
      }, 500); // Wait 500ms to ensure modal is rendered
      } catch (error) {
      console.error('Error initializing scanner:', error);
      setShowScanner(false);
    }
  };

  const handleQrCodeSuccess = async (decodedText) => {
    try {
      if (!decodedText.startsWith('confirmacion-evento-')) {
        alert(isEnglish ? 'Invalid QR code format' : 'Formato de código QR inválido');
        return;
      }

      const idInvitado = parseInt(decodedText.replace('confirmacion-evento-', ''));
      const invitado = invitados.find(inv => inv.idInvitado === idInvitado);

      if (!invitado) {
        alert(isEnglish ? 'Guest not found' : 'Invitado no encontrado');
        return;
      }

      if (invitado.link === 'confirmado-qr') {
        setScanResult({ success: true, invitado });
      } else {
        setIsConfirming(true);
        try {
          const response = await axios.put(`/api/Invitado/${idInvitado}`, {
            ...invitado,
            link: 'confirmado-qr'
          });

          if (response.status === 200) {
            // Actualizar el invitado en la lista local
            const updatedInvitados = invitados.map(inv => 
              inv.idInvitado === idInvitado 
                ? { ...inv, link: 'confirmado-qr' }
                : inv
            );
            setInvitados(updatedInvitados);
            
            setIsConfirming(false);
            setScanResult({ 
              success: true, 
              invitado: { ...invitado, link: 'confirmado-qr' } 
            });
          }
        } catch (error) {
          setIsConfirming(false);
          console.error('Error confirming guest:', error);
          alert(isEnglish ? 'Error confirming guest' : 'Error al confirmar el invitado');
        }
      }
    } catch (error) {
      console.error('Error processing QR code:', error);
      alert(isEnglish ? 'Error processing QR code' : 'Error al procesar el código QR');
    }
  };

  const handleQrCodeError = (error) => {
    // Ignore common scanning errors
    if (error === "No QR code found" || 
        error.includes("NotFoundException") ||
        error.includes("Camera access")) {
      return;
    }
    
    // Log other errors for debugging
    console.error('QR Scanner error:', error);
    
    // Show error message to user only for critical errors
    if (error.includes("NotAllowedError")) {
      setScanResult({
        success: false,
        message: isEnglish ? "Could not access camera. Please allow access." : "No se pudo acceder a la cámara. Por favor, permita el acceso."
      });
    }
  };

  const closeScanner = () => {
    if (qrScanner) {
      qrScanner.clear();
    }
    setShowScanner(false);
    setScanResult(null);
  };

  const handleShare = async (invitado) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: isEnglish ? 'Invitation' : 'Invitación',
          text: isEnglish ? `I invite you to my event. Please confirm your attendance.` : `Te invito a mi evento. Por favor confirma tu asistencia.`,
          url: `${window.location.origin}/share/inv/${invitado.idInvitado}`
        });
      } else {
        // Fallback for browsers that don't support Web Share API
        window.open(`${window.location.origin}/share/inv/${invitado.idInvitado}`, '_blank');
      }
    } catch (error) {
      console.error(isEnglish ? 'Error sharing:' : 'Error al compartir:', error);
    }
  };

  // Add edit function
  const handleEditGuest = async () => {
    try {
      const response = await axios.put(`/api/Invitado/${editingGuest.idInvitado}`, {
        ...editingGuest,
        nombre: editForm.nombre,
        numeroAccesos: editForm.numeroAccesos
      });

      if (response.status === 200) {
        setShowEditModal(false);
        setEditingGuest(null);
        // Reload the page after successful edit
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating guest:', error);
    }
  };

  // Add edit modal component
  const EditModal = () => (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="px-6 py-4 border-b border-gray-200">
          <h3 className="text-xl font-semibold text-gray-800">
            {isEnglish ? "Edit Guest" : "Editar Invitado"}
          </h3>
        </div>
        
        <div className="p-6 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {isEnglish ? "Name" : "Nombre"}
            </label>
            <input
              type="text"
              value={editForm.nombre}
              onChange={(e) => setEditForm({ ...editForm, nombre: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {isEnglish ? "Number of Passes" : "Número de Accesos"}
            </label>
            <input
              type="number"
              min="1"
              value={editForm.numeroAccesos}
              onChange={(e) => setEditForm({ ...editForm, numeroAccesos: parseInt(e.target.value) || 0 })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>

        <div className="px-6 py-4 border-t border-gray-200 flex justify-end space-x-3">
          <button
            onClick={() => setShowEditModal(false)}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-md"
          >
            {isEnglish ? "Cancel" : "Cancelar"}
          </button>
          <button
            onClick={handleEditGuest}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
          >
            {isEnglish ? "Save" : "Guardar"}
          </button>
        </div>
      </div>
    </div>
  );

  // Helper functions inside component to access isEnglish
  const getStatusColor = (status) => {
    switch (status) {
      case 'si':
        return 'bg-green-100 text-green-800';
      case 'confirmado-qr':
        return 'bg-blue-100 text-blue-800';
      case 'no':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-yellow-100 text-yellow-800';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'si':
        return isEnglish ? 'Confirmed' : 'Confirmado';
      case 'confirmado-qr':
        return isEnglish ? 'Confirmed by QR' : 'Confirmado por QR';
      case 'no':
        return isEnglish ? 'Not Attending' : 'No Asistirá';
      default:
        return isEnglish ? 'Pending' : 'Pendiente';
    }
  };

  // Add messages modal component
  const MessagesModal = () => {
    const messages = viewAllMessages 
      ? invitados.filter(inv => inv.mensajeInvitado)
      : selectedMessage ? [selectedMessage] : [];

    return (
      <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-xl shadow-xl w-full max-w-lg max-h-[80vh] overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center sticky top-0 bg-white">
            <h3 className="text-xl font-semibold text-gray-800">
              {viewAllMessages 
                ? (isEnglish ? "Guest Messages" : "Mensajes de Invitados")
                : (isEnglish ? "Guest Message" : "Mensaje del Invitado")
              }
            </h3>
            <button
              onClick={() => {
                setShowMessagesModal(false);
                setSelectedMessage(null);
                setViewAllMessages(false);
              }}
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <FaTimes className="w-5 h-5" />
            </button>
          </div>
          
          <div className="overflow-y-auto p-6 space-y-6 max-h-[60vh]">
            {messages.map((inv, index) => (
              <div 
                key={inv.idInvitado} 
                className={`p-4 bg-gray-50 rounded-lg ${index !== 0 ? 'mt-4' : ''}`}
              >
                <h4 className="font-medium text-gray-900 mb-2">{inv.nombre}</h4>
                <p className="text-gray-600 italic">"{inv.mensajeInvitado}"</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Help Button */}
      <button
        onClick={() => setShowInstructionsModal(true)}
        className="fixed bottom-6 left-6 z-50 animate-pulse bg-white text-indigo-600 p-3 rounded-full shadow-lg hover:bg-indigo-50 transition-colors"
      >
        <FaQuestionCircle className="text-2xl" />
      </button>

      {/* Main Container */}
      <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50">
        <div className="flex">
          {/* Sidebar - Fixed */}
          <div className={`fixed top-0 left-0 h-screen lg:w-80 z-40 transition-transform duration-300 ${
            showSidebar ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
          }`}>
            <div className="h-full bg-white shadow-lg overflow-y-auto">
              <div className="p-6 space-y-6">
                {/* Stats Grid */}
                <div className="grid grid-cols-2 gap-4 mb-6">
                  <button
                    onClick={() => handleFilter('all')}
                    className={`p-4 rounded-lg shadow-sm transition-all ${
                      activeFilter === 'all'
                        ? 'bg-gray-100 shadow-md'
                        : 'bg-white hover:bg-gray-50'
                    }`}
                  >
                    <div className="text-sm text-gray-500">{isEnglish ? "Total Guests" : "Total Invitados"}</div>
                    <div className="text-2xl font-semibold text-gray-900">{totalInvitados}</div>
                  </button>
                  
                  <button
                    onClick={() => handleFilter('confirmed')}
                    className={`p-4 rounded-lg shadow-sm transition-all ${
                      activeFilter === 'confirmed'
                        ? 'bg-green-100 shadow-md'
                        : 'bg-white hover:bg-green-50'
                    }`}
                  >
                    <div className="text-sm text-gray-500">{isEnglish ? "Confirmed" : "Confirmados"}</div>
                    <div className="text-2xl font-semibold text-green-600">{totalConfirmados}</div>
                  </button>
                  
                  <button
                    onClick={() => handleFilter('confirmedQR')}
                    className={`p-4 rounded-lg shadow-sm transition-all ${
                      activeFilter === 'confirmedQR'
                        ? 'bg-blue-100 shadow-md'
                        : 'bg-white hover:bg-blue-50'
                    }`}
                  >
                    <div className="text-sm text-gray-500">{isEnglish ? "Confirmed by QR" : "Confirmados por QR"}</div>
                    <div className="text-2xl font-semibold text-blue-600">{totalConfirmadosQR}</div>
                  </button>
                  
                  <button
                    onClick={() => handleFilter('notAttending')}
                    className={`p-4 rounded-lg shadow-sm transition-all ${
                      activeFilter === 'notAttending'
                        ? 'bg-red-100 shadow-md'
                        : 'bg-white hover:bg-red-50'
                    }`}
                  >
                    <div className="text-sm text-gray-500">{isEnglish ? "Not Attending" : "No Asistirán"}</div>
                    <div className="text-2xl font-semibold text-red-600">{totalNoAsistiran}</div>
                  </button>
                  
                  <button
                    onClick={() => handleFilter('pending')}
                    className={`p-4 rounded-lg shadow-sm transition-all ${
                      activeFilter === 'pending'
                        ? 'bg-yellow-100 shadow-md'
                        : 'bg-white hover:bg-yellow-50'
                    }`}
                  >
                    <div className="text-sm text-gray-500">{isEnglish ? "Pending" : "Pendientes"}</div>
                    <div className="text-2xl font-semibold text-yellow-600">{totalPendientes}</div>
                  </button>
                </div>

                {/* Add Messages button */}
                <button
                  onClick={() => {
                    setViewAllMessages(true);
                    setShowMessagesModal(true);
                    setShowSidebar(false);
                  }}
                  className="w-full px-4 py-3 bg-purple-600 text-white rounded-xl hover:bg-purple-700 transition-colors shadow-sm flex items-center justify-center gap-2"
                >
                  <FaEnvelope className="text-xl" />
                  <span>{isEnglish ? "View Messages" : "Ver Mensajes"}</span>
                </button>

                {/* QR Scanner Button */}
                <button
                  onClick={() => {
                    initializeScanner();
                    setShowSidebar(false); // Close sidebar on mobile
                  }}
                  className="w-full px-4 py-3 bg-[#6366F1] text-white rounded-xl hover:bg-[#5558E6] transition-colors shadow-sm flex items-center justify-center gap-2"
                >
                  <FaQrcode className="text-xl" />
                  <span>{isEnglish ? "Scan QR" : "Escanear QR"}</span>
                </button>

              </div>
            </div>
          </div>

          {/* Mobile Toggle Button */}
          <button
            onClick={() => setShowSidebar(!showSidebar)}
            className="lg:hidden fixed bottom-6 right-6 z-50 bg-[#6366F1] text-white p-4 rounded-full shadow-lg hover:bg-[#5558E6] transition-colors"
          >
            {showSidebar ? <FaTimes /> : <FaBars />}
          </button>

          {/* Main Content - With left margin for sidebar */}
          <div className="w-full lg:ml-80 min-h-screen">
            <div className="p-6">
              {/* Header */}
              <div className="mb-8">
                <h1 className="text-2xl font-bold text-gray-900 mb-6">
                  {isEnglish ? "Guest List" : "Lista de Invitados"}
                </h1>
                <button
                  onClick={() => setShowForm(true)}
                  className="w-full bg-[#6366F1] text-white rounded-xl py-3.5 px-4 flex items-center justify-center gap-2 hover:bg-[#5558E6] transition-colors shadow-sm"
                >
                  <span className="text-xl font-light">+</span>
                  <span className="font-medium">{isEnglish ? "Add Guest" : "Agregar Invitado"}</span>
                </button>
              </div>

              {/* Add search input in the main content area */}
              <div className="flex-1 p-4">
                {/* Search input */}
                <div className="mb-6">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder={isEnglish ? "Search guest..." : "Buscar invitado..."}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  </div>
                </div>

                {/* Guest list */}
                <div className="space-y-4">
                  {filteredGuests.map((invitado) => (
                    <div
                      key={invitado.idInvitado}
                      className="bg-white rounded-lg shadow p-4"
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="text-lg font-semibold text-gray-800">{invitado.nombre}</h3>
                          <p className="text-sm text-gray-500">
                            {isEnglish ? "Passes" : "Accesos"}: {invitado.accesos}
                          </p>
                          {invitado.mensajeInvitado && (
                            <p className="text-sm text-gray-600 mt-2 italic">
                              Mensaje Recibido
                            </p>
                          )}
                          <div className="mt-2">
                            <span
                              className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                                invitado.link
                              )}`}
                            >
                              {getStatusText(invitado.link)}
                            </span>
                          </div>
                        </div>
                        <div className="relative">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              const menu = e.currentTarget.nextElementSibling;
                              const allMenus = document.querySelectorAll('.guest-menu');
                              allMenus.forEach(m => {
                                if (m !== menu) m.classList.add('hidden');
                              });
                              menu.classList.toggle('hidden');
                            }}
                            className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-50 transition-colors"
                          >
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                            </svg>
                          </button>
                          <div className="guest-menu hidden absolute right-0 mt-2 w-56 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                            <div className="py-1" role="menu">
                              <button
                                onClick={() => {
                                  setEditingGuest(invitado);
                                  setEditForm({
                                    nombre: invitado.nombre,
                                    numeroAccesos: invitado.accesos
                                  });
                                  setShowEditModal(true);
                                }}
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center space-x-3"
                                role="menuitem"
                              >
                                <FaEdit className="text-indigo-600" />
                                <span>{isEnglish ? "Edit Guest" : "Editar Invitado"}</span>
                              </button>
                              <button
                                onClick={() => handleShare(invitado)}
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center space-x-3"
                                role="menuitem"
                              >
                                <FaPaperPlane className="text-blue-600" />
                                <span>{isEnglish ? "Share Invitation" : "Compartir Invitación"}</span>
                              </button>
                              <button
                                onClick={() => window.open(`/share/inv/${invitado.idInvitado}`, '_blank')}
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center space-x-3"
                                role="menuitem"
                              >
                                <FaEye className="text-blue-600" />
                                <span>{isEnglish ? "View Invitation" : "Ver Invitación"}</span>
                              </button>
                              <button
                                onClick={() => {
                                  if (window.confirm(isEnglish ? 'Are you sure you want to delete this guest?' : '¿Está seguro de que desea eliminar este invitado?')) {
                                    deleteInvitado(invitado.idInvitado);
                                  }
                                }}
                                className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50 flex items-center space-x-3 border-t border-gray-100"
                                role="menuitem"
                              >
                                <FaRegTrashAlt />
                                <span>{isEnglish ? "Delete Guest" : "Eliminar Invitado"}</span>
                              </button>
                              {invitado.mensajeInvitado && (
                                <button
                                  onClick={() => {
                                    setSelectedMessage(invitado);
                                    setShowMessagesModal(true);
                                  }}
                                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center space-x-3"
                                  role="menuitem"
                                >
                                  <FaEnvelope className="text-purple-600" />
                                  <span>{isEnglish ? "View Message" : "Ver Mensaje"}</span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

      {/* Form Modal */}
        {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl w-full max-w-md">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold text-gray-900">
                  {isEnglish ? "Add New Guest" : "Agregar Nuevo Invitado"}
                </h3>
                <button
                  onClick={() => setShowForm(false)}
                  className="text-gray-400 hover:text-gray-600 p-1"
                >
                  <FaTimes className="w-5 h-5" />
                </button>
              </div>
            <form onSubmit={handleAgregarInvitado} className="space-y-6">
              <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                  {isEnglish ? "Guest Name" : "Nombre del Invitado"}
                </label>
                <input
                  type="text"
                  name="Nombre"
                  value={nuevoInvitado.Nombre}
                  onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  required
                    placeholder={isEnglish ? "Enter name" : "Ingrese el nombre"}
                />
              </div>
              <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {isEnglish ? "Number of Access" : "Número de Accesos"}
                </label>
                <input
                  type="number"
                  name="Accesos"
                  value={nuevoInvitado.Accesos}
                  onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  required
                    placeholder={isEnglish ? "Enter number of access" : "Ingrese el número de accesos"}
                />
              </div>
                <div className="flex justify-end space-x-3 pt-6">
                  <button
                    type="button"
                    onClick={() => setShowForm(false)}
                    className="px-6 py-2.5 text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200 font-medium"
                  >
                    {isEnglish ? "Cancel" : "Cancelar"}
                  </button>
              <button
                type="submit"
                    className="px-6 py-2.5 bg-indigo-600 text-white rounded-xl hover:bg-indigo-700 disabled:opacity-50 font-medium"
                disabled={isAdding}
              >
                    {isAdding ? (isEnglish ? "Adding..." : "Agregando...") : (isEnglish ? "Add Guest" : "Agregar Invitado")}
              </button>
                </div>
            </form>
            </div>
          </div>
          </div>
        )}

      {/* QR Scanner Modal */}
      {showScanner && (
        <div className="fixed inset-0 bg-black bg-opacity-60 z-50 flex items-center justify-center p-4 backdrop-blur-sm">
          <div className="bg-white rounded-3xl w-full max-w-md shadow-2xl">
            <div className="p-8">
              <div className="flex justify-between items-center mb-8">
                <h3 className="text-2xl font-bold text-gray-900 tracking-tight">
                  {isEnglish ? "Scan QR Code" : "Escanear Código QR"}
                </h3>
                <button
                  onClick={closeScanner}
                  className="text-gray-400 hover:text-gray-600 p-2 hover:bg-gray-100 rounded-full transition-colors"
                >
                  <FaTimes className="w-6 h-6" />
                </button>
              </div>
              <div id="qr-reader" className="w-full rounded-2xl overflow-hidden shadow-inner border-2 border-indigo-100"></div>
              {scanResult && (
                <div className={`mt-8 p-6 rounded-2xl shadow-sm ${
                  scanResult.success ? 'bg-green-50 text-green-800 border-2 border-green-200' : 
                  'bg-red-50 text-red-800 border-2 border-red-200'
                }`}>
                  <p className="font-semibold text-lg">{scanResult.message}</p>
                  {scanResult.invitado && (
                    <div className="mt-4 space-y-2 text-base">
                      <p className="flex items-center"><span className="font-medium mr-2">{isEnglish ? "Name:" : "Nombre:"}</span> {scanResult.invitado.nombre}</p>
              </div>
                  )}
              </div>
              )}
            </div>
          </div>
            </div>
      )}

      {/* Click outside handler for menus */}
      <script>
        {`
          document.addEventListener('click', function(e) {
            if (!e.target.closest('.guest-menu') && !e.target.closest('button')) {
              document.querySelectorAll('.guest-menu').forEach(menu => {
                menu.classList.add('hidden');
              });
            }
          });
        `}
      </script>

      {/* Instructions Modal */}
      {showInstructionsModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex justify-between items-center">
              <h2 className="text-2xl font-bold text-gray-800">Guía de Uso</h2>
                          <button
                onClick={() => setShowInstructionsModal(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <FaTimes className="text-xl" />
                          </button>
            </div>
            
            <div className="px-6 py-4 space-y-6">
              {/* Filters and Statistics Section */}
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-indigo-600">Filtros y Estadísticas</h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    <li>En la barra lateral encontrarás el total de invitados y su estado de asistencia</li>
                    <li>Usa los botones de filtro para ver invitados por estado: Confirmados, Pendientes o No Asistirán</li>
                  </ul>
        </div>
      </div>

              {/* QR Scanner Section */}
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-indigo-600">Escáner QR</h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    <li>Utiliza el botón "Escanear QR" para confirmar la asistencia de invitados</li>
                    <li>El codigo QR se encuentra en cada invitación de tus invitados</li>
                    <li>El escáner validará automáticamente el código y actualizará el estado del invitado</li>
                  </ul>
                </div>
            </div>
            
              {/* Guest Actions Section */}
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-indigo-600">Acciones por Invitado</h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    <li>Cada invitado tiene opciones para compartir su invitación y eliminar su registro</li>
                    <li>El estado de cada invitado se muestra con un color distintivo para fácil identificación</li>
                  </ul>
                    </div>
                  </div>

              {/* Adding Guests Section */}
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-indigo-600">Agregar Invitados</h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    <li>Usa el botón "+" para agregar nuevos invitados</li>
                    <li>Ingresa el nombre del invitado y el número de pases asignados</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="sticky bottom-0 bg-white border-t border-gray-200 px-6 py-4 flex justify-end">
                <button
                onClick={() => setShowInstructionsModal(false)}
                className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                >
                Entendido
                </button>
            </div>
          </div>
        </div>
      )}

      {/* Add edit modal */}
      {showEditModal && <EditModal />}

      {/* Add new states for scan feedback */}
      {isConfirming && <LoadingMessage />}
      {scanResult?.success && <SuccessMessage invitado={scanResult.invitado} />}

      {/* Add messages modal */}
      {showMessagesModal && <MessagesModal />}
    </div>
  );
};

export default InvitadosTabla2;
