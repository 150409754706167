import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Button } from "reactstrap";
import { ModalAgregarEvento } from "../Modals/ModalAgregarEvento";
import { ModalEditarEvento } from "../Modals/ModarEditarEvento";
import { ModalEditarImagenes } from "../Modals/ModalImages";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import { validateToken } from "../../../components/validateToken";
import { AddHistorial } from "../../../components/functions/AddHistorial";
import { useApi } from "../../../hooks/useApi";
import { getHeaders, handleLogout } from "../../../components/auth/authHelpers";

export const Eventos = () => {
  const { error, callApi } = useApi();
  const [datosEventos, setDatosEventos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [infoEdit, setInfoEdit] = useState(null);
  const [infoImages, setInfoImages] = useState(null);
  const [modalDetalle, setModalDetalle] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalImagen, setModalImagen] = useState(false);
  const [modalAgregar, setModalAgregar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchIdEvento, setSearchIdEvento] = useState("");
  const [searchFechaFin, setSearchFechaFin] = useState("");
  const [searchVendedor, setSearchVendedor] = useState("");
  const [searchFechaInicio, setSearchFechaInicio] = useState("");
  const [searchFechaFinFiltro, setSearchFechaFinFiltro] = useState("");
  const [searchDiseño, setSearchDiseño] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(100);
  const [totalEvents, setTotalEvents] = useState(0);
  const [count, setCount] = useState(0);
  const [totalMonto, setTotalMonto] = useState(0);
  const [totalEsperado, setTotalEsperado] = useState(0);
  const [userName, setUserName] = useState("");
  const [admins, setAdmins] = useState([]);
  const [agreado, setAgreado] = useState(false);
  const toggleModalAgregar = () => setModalAgregar(true);
  const toggleModalDetalle = () => setModalDetalle(!modalDetalle);
  const [eventosMontoMenorQueTotal, setEventosMontoMenorQueTotal] = useState([]);
  const [filterFechaFin, setFilterFechaFin] = useState('');
  const [filterDiseño, setFilterDiseño] = useState('');
  const toggleModalEditar = (e) => {
    setInfoEdit(e);
    setModalEditar(true);
  };

  const toggleModalImages = async (id) => {
    const response = await axios.get(`/api/Images/FromEvent/${id}`);
    if (response.status !== 200) {
      toast.error("No se pudo obtener la información de las imágenes para este evento");
      return;
    }

    setInfoImages(response.data);
    setModalImagen(true);
  };

  const fetchData = async (queryParams) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/Eventos/filtrado`, {
        params: queryParams,
        headers: await getHeaders()
      });
      setDatosEventos(response.data);
      setTotalEvents(parseInt(response.headers["x-total-count"], 10));
      setCount(response.data.length);

      const totalMonto = response.data.reduce(
        (acc, evento) => acc + parseFloat(evento.monto || 0),
        0
      );
      setTotalMonto(totalMonto);

      const totalEsperado = response.data.reduce(
        (acc, evento) => acc + parseFloat(evento.total || 0),
        0
      );
      setTotalEsperado(totalEsperado);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error al cargar los datos");
    }
  };

  const debounceFetchData = useCallback(
    debounce((queryParams) => fetchData(queryParams), 1500),
    []
  );

  const fetchEventosMontoMenorQueTotal = async () => {
    try {
      const response = await axios.get("/api/Eventos/monto-menor-que-total", {
        headers: await getHeaders()
      });
      setEventosMontoMenorQueTotal(response.data);
    } catch (error) {
      console.error("Error fetching events with monto less than total", error);
    }
  };

  const resetForm = async (idEvento, tipoEvento) => {
    const confirmed = window.confirm(
      `¿Deseas resetear la información de la invitación ${idEvento}?`
    );

    if (confirmed) {
      try {
        toast.loading(`Reseteando formulario ${idEvento}`);
        await axios.delete(`/api/Eventos/${tipoEvento === "Boda" ? "boda/" : ""}${idEvento}`, {
          headers: await getHeaders()
        });
        debounceFetchData({
          nombre: searchTerm,
          idEvento: searchIdEvento,
          fechaFin: searchFechaFin,
          fechaInicio: searchFechaInicio,
          fechaFinFiltro: searchFechaFinFiltro,
          vendedor: searchVendedor,
          diseño: searchDiseño,
          pageNumber: pageNumber,
          pageSize: pageSize,
        });
        toast.dismiss();
        toast.success("Se ha reseteado correctamente");
      } catch (error) {
        console.error("Error al eliminar el invitado:", error);
        toast.error("Error al resetear");
      }
    }
  };

  const fetchAdmins = async () => {
    try {
      const responseAdmin = await callApi('get', '/Admins');
      setAdmins(responseAdmin);
    } catch (error) {
      console.error("Error al obtener administradores:", error);
    }
  };

  useEffect(() => {
    debounceFetchData({
      nombre: searchTerm,
      idEvento: searchIdEvento,
      fechaFin: searchFechaFin,
      fechaInicio: searchFechaInicio,
      fechaFinFiltro: searchFechaFinFiltro,
      vendedor: searchVendedor,
      diseño: searchDiseño,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
  }, [
    searchTerm,
    searchIdEvento,
    searchFechaFin,
    searchFechaInicio,
    searchFechaFinFiltro,
    searchVendedor,
    searchDiseño,
    pageNumber,
    pageSize,
    debounceFetchData,
    agreado
  ]);

  useEffect(() => {
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);
    };
    fetchAdmins();
    validateAndFetchData();
    fetchEventosMontoMenorQueTotal();
  }, []);

  const handleNextPage = () => {
    if ((pageNumber + 1) * pageSize < totalEvents) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const handlePreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-2 sm:p-4 md:p-6 lg:p-8">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="max-w-full mx-auto bg-white rounded-lg shadow-lg p-4 sm:p-6 md:p-8">
          {userName && (
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-6">
              ¡Excelente día, {userName}! 🌟
            </h1>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
            <button
              className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              onClick={toggleModalAgregar}
            >
              Agregar Evento
            </button>
            <input
              type="text"
              placeholder="Buscar por nombre..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              placeholder="Filtrar por ID de Evento..."
              value={searchIdEvento}
              onChange={(e) => setSearchIdEvento(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
            <select
              value={searchFechaFin}
              onChange={(e) => setSearchFechaFin(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Filtrar por Estatus...</option>
              <option value="Nada">Nada</option>
              <option value="Formulario">Formulario</option>
              <option value="Entregado">Entregado</option>
              <option value="Formateado">Formateado</option>
              <option value="Test">Test</option>
              <option value="Ejemplos">Ejemplos</option>
            </select>
            <input
              type="date"
              placeholder="Filtrar por Fecha de Inicio..."
              value={searchFechaInicio}
              onChange={(e) => setSearchFechaInicio(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select
              value={searchVendedor}
              onChange={(e) => setSearchVendedor(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Filtrar por Vendedor</option>
              {Array.isArray(admins) && admins.length > 0 ? (
                admins.map((item) => (
                  <option key={item.nombre} value={item.nombre}>
                    {item.nombre}
                  </option>
                ))
              ) : (
                <option disabled>Cargando...</option>
              )}
            </select>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
            <select
              value={searchDiseño}
              onChange={(e) => setSearchDiseño(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Filtrar por Diseño</option>
              <option value="1">Diseño Clasico</option>
              <option value="2">Diseño Vip</option>
            </select>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 mb-6 text-center">
            <p className="font-semibold bg-blue-100 p-2 rounded-lg">Total de Eventos: <span className="text-blue-600">{count}</span></p>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg overflow-hidden">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Id</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Nombre</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Comprador</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Tipo</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Paquete</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Fecha</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Monto</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Detalles</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Estatus</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Acciones</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {datosEventos.map((evento) => (
                  <tr key={evento.idEvento} className="hover:bg-gray-50">
                    <td className="px-2 py-2 whitespace-nowrap text-xs">{evento.idEvento.toString()} <AddHistorial evento={evento.idEvento.toString()} nombre={userName} /></td>
                    <td className="px-2 py-2 whitespace-nowrap text-xs">{evento.nombre}</td>
                    <td className="px-2 py-2 whitespace-nowrap text-xs">
                      {evento.nombreComprador}<br />
                      {evento.numComprador}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-xs">{evento.tipoEvento}</td>
                    <td className="px-2 py-2 whitespace-nowrap text-xs">{evento.paquete}</td>
                    <td className="px-2 py-2 whitespace-nowrap text-xs">
                      {evento.fechaInicio}<br />
                      {evento.vendedor}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-xs">
                      ${evento.monto} / ${evento.total}
                    </td>
                    <td className="px-2 py-2 text-xs">
                      {evento.detalles && evento.detalles.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-xs">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${evento.fechaFin === "Nada"
                          ? "bg-red-100 text-red-800"
                          : evento.fechaFin === "Formulario"
                            ? "bg-blue-100 text-blue-800"
                            : evento.fechaFin === "Formateado"
                              ? "bg-purple-100 text-purple-800"
                              : evento.fechaFin === "Test"
                                ? "bg-yellow-100 text-yellow-800"
                                : evento.fechaFin === "Ejemplos"
                                  ? "bg-yellow-100 text-yellow-800"
                                  : evento.fechaFin === "Entregado"
                                    ? "bg-green-100 text-green-800"
                                    : ""
                          }`}
                      >
                        {evento.fechaFin}
                      </span>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-sm font-medium">
                      <div className="flex flex-wrap gap-2">

                        {
                          evento.enableInvitados ? "" :
                            <a
                              href={`/compartir-invitacion?id=${evento.idEvento}`}
                              className="bg-white text-blue-400 hover:bg-blue-100 p-2 rounded-full"
                            >
                              ➦
                            </a>
                        }
                        <a
                          href={
                            evento.tipoEvento === "Boda"
                              ? `/admin-boda-detalles?id=${evento.idEvento}`
                              : `/admin-xv-detalles?id=${evento.idEvento}`
                          }
                          className="bg-indigo-100 text-indigo-600 hover:bg-indigo-200 p-2 rounded-full"
                        >
                          🔎
                        </a>
                        {evento.paquete !== "Diamante" && !evento.enableInvitados && (
                          <a
                            href={
                              evento.tipoEvento === "Boda"
                                ? evento.ingles
                                  ? `/en/wedding-auto?id=${evento.idEvento}`
                                  : `/share/2/${evento.idEvento}`
                                : evento.ingles
                                  ? `/en/event-auto?id=${evento.idEvento}`
                                  : `/share/2/${evento.idEvento}`
                            }
                            className="bg-blue-100 text-blue-600 hover:bg-blue-200 p-2 rounded-full"
                          >
                            👀
                          </a>
                        )}
                        <a
                          href={
                            evento.tipoEvento === "Boda"
                              ? evento.ingles
                                ? `/form-wedding?id=${evento.idEvento}`
                                : evento.diseño === "2"
                                  ? `/formulario-evento?id=${evento.idEvento}`
                                  : `/formulario-boda?id=${evento.idEvento}`
                              : evento.ingles
                                ? `/form-event?id=${evento.idEvento}`
                                : evento.diseño === "2"
                                  ? `/formulario-evento-invitado?id=${evento.idEvento}`
                                  : `/formulario-xv?id=${evento.idEvento}`
                          }
                          className="bg-green-100 text-green-600 hover:bg-green-200 p-2 rounded-full"
                        >
                          📨
                        </a>
                        {(evento.paquete === "Diamante" || evento.enableInvitados) && (
                          <a
                            href={
                              evento.ingles
                                ? `/guests-generate?id=${evento.idEvento}`
                                : evento.diseño === "2"
                                  ? `/2/invitados-generate?id=${evento.idEvento}`
                                  : `/invitados-generate?id=${evento.idEvento}`
                            }
                            className="bg-yellow-100 text-yellow-600 hover:bg-yellow-200 p-2 rounded-full"
                          >
                            😎
                          </a>
                        )}
                        <button
                          onClick={() => toggleModalEditar(evento)}
                          className="bg-gray-100 text-gray-600 hover:bg-gray-200 p-2 rounded-full"
                        >
                          ✎
                        </button>
                        <button
                          onClick={() => resetForm(evento.idEvento, evento.tipoEvento)}
                          className="bg-red-100 text-red-600 hover:bg-red-200 p-2 rounded-full"
                        >
                          🔄
                        </button>
                        <button
                          onClick={() => toggleModalImages(evento.idEvento)}
                          className="bg-purple-100 text-purple-600 hover:bg-purple-200 p-2 rounded-full"
                        >
                          🖼️
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-6">
            <button
              disabled={pageNumber === 0}
              onClick={handlePreviousPage}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
            >
              Anterior
            </button>
            <button
              disabled={(pageNumber + 1) * pageSize >= totalEvents}
              onClick={handleNextPage}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
            >
              Siguiente
            </button>
          </div>

          <div className="mt-6 text-center">
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      )}

      <div className="bg-white p-4 md:p-6 rounded-lg shadow mt-8">
        <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Eventos con Monto Menor que Total</h2>
        <div className="mb-4">
          <label htmlFor="filterFechaFin" className="block text-gray-700">Filtrar por FechaFin:</label>
          <select
            id="filterFechaFin"
            className="border rounded p-2 w-full"
            onChange={(e) => setFilterFechaFin(e.target.value)}
          >
            <option value="">Mostrar todos</option>
            <option value="Formateado">Formateado</option>
            <option value="Formulario">Formulario</option>
            <option value="Nada">Nada</option>
            <option value="Entregado">Entregado</option>
          </select>
        </div>
        <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2 text-left">Id</th>
                <th className="border p-2 text-left">Nombre</th>
                <th className="border p-2 text-left">Num Comprador</th>
                <th className="border p-2 text-left">Tipo Evento</th>
                <th className="border p-2 text-left">Paquete</th>
                <th className="border p-2 text-left">Fecha Inicio</th>
                <th className="border p-2 text-left">Fecha Fin</th>
                <th className="border p-2 text-left">Monto</th>
                <th className="border p-2 text-left">Detalles</th>
                <th className="border p-2 text-left">Total</th>
                <th className="border p-2 text-left">Socio</th>
                <th className="border p-2 text-left">Enable Evento</th>
              </tr>
            </thead>
            <tbody>
              {eventosMontoMenorQueTotal
                .filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin)
                .map((evento, index) => {
                  let rowColor = '';
                  switch (evento.fechaFin) {
                    case 'Formateado':
                      rowColor = 'bg-blue-100';
                      break;
                    case 'Formulario':
                      rowColor = 'bg-yellow-100';
                      break;
                    case 'Nada':
                      rowColor = 'bg-red-100';
                      break;
                    case 'Entregado':
                      rowColor = 'bg-green-100';
                      break;
                    default:
                      rowColor = '';
                  }
                  return (
                    <tr key={index} className={rowColor}>
                      <td className="border p-2">{evento.idEvento}</td>
                      <td className="border p-2">{evento.nombre}</td>
                      <td className="border p-2">{evento.numComprador}</td>
                      <td className="border p-2">{evento.tipoEvento}</td>
                      <td className="border p-2">{evento.paquete}</td>
                      <td className="border p-2">{evento.fechaInicio}</td>
                      <td className="border p-2">{evento.fechaFin}</td>
                      <td className="border p-2">{evento.monto}</td>
                      <td className="border p-2">{evento.detalles}</td>
                      <td className="border p-2">{evento.total}</td>
                      <td className="border p-2">{evento.socio}</td>
                      <td className="border p-2">{evento.enableEvento ? 'Sí' : 'No'}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <p className="mt-4 font-semibold">
          Total de eventos: {eventosMontoMenorQueTotal.filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin).length}
        </p>
      </div>

      <ModalAgregarEvento
        modalAgregar={modalAgregar}
        setModalAgregar={setModalAgregar}
        admin={userName}
        agreado={agreado}
        setAgreado={setAgreado}
      />

      {modalEditar && (
        <ModalEditarEvento
          modalEditar={modalEditar}
          setModalEditar={setModalEditar}
          infoEdit={infoEdit}
          agreado={agreado}
          setAgreado={setAgreado}
        />
      )}

      {modalImagen && (
        <ModalEditarImagenes
          modalImages={modalImagen}
          setModalImages={setModalImagen}
          infoImages={infoImages}
          edit={true}
        />
      )}
    </div>
  );
};
