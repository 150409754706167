/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import {
    Label,
    Input,
    Modal,
    ModalBody,
} from "reactstrap";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ComboBox } from './ComboBox'
import './ImageFormComponent.css'

export const ImageFormComponent = ({ label, field, element, errors, touched, setFieldValue, elementClass, preset, ingles, edit = false }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [crop, setCrop] = useState({
        unit: '%',
        width: 90,
        height: 160,
        x: 5,
        y: 5,
        aspect: 9/16
    });
    const [rotation, setRotation] = useState(0);
    const [isAdvancedEdit, setIsAdvancedEdit] = useState(false);

    const imgField = `${field}Img`;
    const fileField = `${field}File`;
    const divClass = elementClass ?? "col-span-1 m-3";
    const src = element[imgField];

    const openEditor = async (file) => {
        try {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
                setRotation(0);
                setIsAdvancedEdit(false);
                setIsEditModalOpen(true);
            };
            reader.readAsDataURL(file);
        } catch (error) {
            console.error('Error al abrir el editor:', error);
        }
    };

    const handleImageSelect = async (event) => {
        if (event.target.files?.[0]) {
            if (edit) {
                await openEditor(event.target.files[0]);
            } else {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = () => {
                    document.getElementById(`${imgField}Preview`).setAttribute("src", reader.result);
                    setFieldValue(fileField, file);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleRotate = () => {
        if (!isAdvancedEdit) {
            setRotation((prev) => (prev + 90) % 360);
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = selectedImage;
        link.download = 'edited-image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDone = async (image) => {
        if (image) {
            try {
                if (isAdvancedEdit) {
                    const blob = await image.getBlob();
                    const previewUrl = URL.createObjectURL(blob);
                    document.getElementById(`${imgField}Preview`).setAttribute("src", previewUrl);
                    
                    const editedFile = new File([blob], 'edited-image.jpg', {
                        type: 'image/jpeg',
                    });
                    
                    setFieldValue(fileField, editedFile);
                    setIsEditModalOpen(false);
                    setRotation(0);
                } else {
                    // Crear un canvas para aplicar la rotación
                    const img = new Image();
                    img.src = image;
                    await new Promise((resolve) => {
                        img.onload = resolve;
                    });

                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Ajustar dimensiones del canvas según la rotación
                    if (rotation === 90 || rotation === 270) {
                        canvas.width = img.height;
                        canvas.height = img.width;
                    } else {
                        canvas.width = img.width;
                        canvas.height = img.height;
                    }

                    // Aplicar transformaciones
                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    ctx.rotate((rotation * Math.PI) / 180);
                    ctx.drawImage(img, -img.width / 2, -img.height / 2);

                    // Convertir a blob y guardar
                    const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
                    const previewUrl = URL.createObjectURL(blob);
                    document.getElementById(`${imgField}Preview`).setAttribute("src", previewUrl);
                    
                    const editedFile = new File([blob], 'edited-image.jpg', {
                        type: 'image/jpeg',
                    });
                    
                    setFieldValue(fileField, editedFile);
                    setIsEditModalOpen(false);
                    setRotation(0);
                }
            } catch (error) {
                console.error('Error al procesar la imagen:', error);
            }
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <div className={`${divClass} bg-white shadow-md rounded-lg p-4`}>
                <h5 className="text-lg font-semibold mb-3 text-center">{label}</h5>
                <div className="flex flex-col items-center">
                    <div className="mb-4 w-full flex justify-center">
                        <div className="relative w-full max-w-[250px]" style={{ paddingBottom: '177.78%' }}>
                            <div className="absolute inset-0 bg-gray-100 rounded-lg overflow-hidden">
                                <img 
                                    id={`${imgField}Preview`} 
                                    className="w-full h-full object-cover" 
                                    alt="" 
                                    src={src || `https://placehold.co/900x1600/e2e8f0/a0aec0`} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full max-w-xs">
                        <div className="flex flex-col gap-2">
                            <div className="relative">
                                <Input
                                    type="file"
                                    accept="image/*"
                                    placeholder="Seleccionar Foto"
                                    id={fileField}
                                    name={fileField}
                                    onChange={handleImageSelect}
                                    invalid={errors[imgField] && touched[imgField]}
                                    className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                />
                                <Label htmlFor={fileField} className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer hover:bg-blue-600 transition duration-300 flex justify-center">
                                    {ingles ? "Select Photo" : "Seleccionar Foto"}
                                </Label>
                            </div>
                            {edit && src && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setSelectedImage(src);
                                        setIsEditModalOpen(true);
                                    }}
                                    className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded transition duration-300 flex justify-center items-center gap-2"
                                >
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>
                                    {ingles ? "Edit Photo" : "Editar Foto"}
                                </button>
                            )}
                        </div>
                        {touched[imgField] && errors[imgField] && (
                            <div className="text-red-500 mt-2 text-center">{errors[imgField]}</div>
                        )}
                    </div>
                </div>
            </div>

            {edit && (
                <Modal isOpen={isEditModalOpen} toggle={() => setIsEditModalOpen(false)} size="xl" className="modal-dark" style={{ maxWidth: '100vw', margin: '0' }}>
                    <ModalBody className="p-0">
                        <div className="image-editor-container bg-gray-100 min-h-screen flex flex-col">
                            <div className="flex justify-between items-center p-4 bg-white border-b">
                                <div className="text-lg font-semibold">
                                    {ingles ? "Edit image" : "Editar imagen"}
                                </div>
                                <div className="flex gap-2">
                                    <button 
                                        onClick={handleDownload}
                                        className="p-2 rounded hover:bg-gray-100"
                                        title={ingles ? "Download" : "Descargar"}
                                    >
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                        </svg>
                                    </button>
                                    {!isAdvancedEdit && (
                                        <button 
                                            onClick={handleRotate}
                                            className="p-2 rounded hover:bg-gray-100"
                                            title={ingles ? "Rotate" : "Rotar"}
                                        >
                                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                            </svg>
                                        </button>
                                    )}
                                    <button 
                                        onClick={() => setIsAdvancedEdit(!isAdvancedEdit)}
                                        className={`p-2 rounded hover:bg-gray-100 ${isAdvancedEdit ? 'bg-blue-100' : ''}`}
                                        title={ingles ? "Advanced Edit" : "Edición Avanzada"}
                                    >
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            {selectedImage && (
                                <div className="flex-1 p-4 flex flex-col">
                                    <div className="flex-1 flex items-center justify-center bg-white rounded-lg overflow-hidden" style={{ minHeight: 'calc(100vh - 200px)' }}>
                                        {isAdvancedEdit ? (
                                            <div className="w-full h-full flex items-center justify-center" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                                                <ReactCrop
                                                    src={selectedImage}
                                                    crop={crop}
                                                    onChange={(newCrop) => setCrop(newCrop)}
                                                    onComplete={(c) => {
                                                        setCrop(c);
                                                        handleDone(selectedImage);
                                                    }}
                                                    aspect={9/16}
                                                />
                                            </div>
                                        ) : (
                                            <img
                                                src={selectedImage}
                                                alt="Preview"
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: 'calc(100vh - 200px)',
                                                    transform: `rotate(${rotation}deg)`,
                                                    transition: 'transform 0.3s ease'
                                                }}
                                            />
                                        )}
                                    </div>
                                    {!isAdvancedEdit && (
                                        <div className="flex justify-end gap-4 mt-4">
                                            <button
                                                onClick={() => {
                                                    setIsEditModalOpen(false);
                                                    setRotation(0);
                                                }}
                                                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                            >
                                                {ingles ? "Cancel" : "Cancelar"}
                                            </button>
                                            <button
                                                onClick={() => handleDone(selectedImage)}
                                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                            >
                                                {ingles ? "Save" : "Guardar"}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};